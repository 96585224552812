import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc, orderBy, Timestamp, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { User } from 'firebase/auth';

interface Poll {
  id: string;
  Nume: string;
  descriere: string;
  creationDate: Date;
  endDate: Date;
  favorabil: number;
  nefavorabil: number;
  voter: string | null;
}

interface PollFormData {
  Nume: string;
  descriere: string;
  endDate: string;
}

export const Sondaje: React.FC = () => {
  const [polls, setPolls] = useState<Poll[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAddingPoll, setIsAddingPoll] = useState(false);
  const [formData, setFormData] = useState<PollFormData>({ Nume: '', descriere: '', endDate: '' });
  const [selectedPoll, setSelectedPoll] = useState<Poll | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        checkAdminStatus(user.uid);
      } else {
        setIsAdmin(false);
      }
    });

    fetchPolls();

    return () => unsubscribe();
  }, []);

  const checkAdminStatus = async (uid: string) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      setIsAdmin(userDoc.exists() && userDoc.data().role === 'admin');
    } catch (error) {
      console.error("Error checking admin status:", error);
      setIsAdmin(false);
    }
  };

  const fetchPolls = async () => {
    setLoading(true);
    setError(null);
    try {
      const q = query(collection(db, 'sondaje'), orderBy('creationDate', 'desc'));
      const querySnapshot = await getDocs(q);
      const fetchedPolls: Poll[] = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        creationDate: doc.data().creationDate.toDate(),
        endDate: doc.data().endDate.toDate(),
        voter: doc.data().voter || null,
      } as Poll));
      setPolls(fetchedPolls);
    } catch (err) {
      console.error("Error fetching polls:", err);
      setError("An error occurred while fetching polls. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddPoll = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isAdmin) {
      setError("Only admins can add new polls.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const newPoll = {
        Nume: formData.Nume,
        descriere: formData.descriere,
        creationDate: Timestamp.now(),
        endDate: Timestamp.fromDate(new Date(formData.endDate)),
        favorabil: 0,
        nefavorabil: 0,
        voter: null,
      };
      await addDoc(collection(db, 'sondaje'), newPoll);
      setIsAddingPoll(false);
      setFormData({ Nume: '', descriere: '', endDate: '' });
      fetchPolls();
    } catch (err) {
      console.error("Error adding new poll:", err);
      setError("An error occurred while adding the poll. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePoll = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isAdmin || !selectedPoll) {
      setError("Only admins can update polls.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const pollRef = doc(db, 'sondaje', selectedPoll.id);
      await updateDoc(pollRef, {
        Nume: formData.Nume,
        descriere: formData.descriere,
        endDate: Timestamp.fromDate(new Date(formData.endDate)),
      });
      setIsEditing(false);
      setSelectedPoll(null);
      setFormData({ Nume: '', descriere: '', endDate: '' });
      fetchPolls();
    } catch (err) {
      console.error("Error updating poll:", err);
      setError("An error occurred while updating the poll. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePoll = async (pollId: string) => {
    if (!isAdmin) {
      setError("Only admins can delete polls.");
      return;
    }
    if (!window.confirm('Are you sure you want to delete this poll?')) return;
    setLoading(true);
    setError(null);
    try {
      await deleteDoc(doc(db, 'sondaje', pollId));
      fetchPolls();
    } catch (err) {
      console.error("Error deleting poll:", err);
      setError("An error occurred while deleting the poll. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const calculatePercentage = (value: number, total: number) => {
    if (total === 0) return 0;
    return ((value / total) * 100).toFixed(1);
  };

  const isPollActive = (endDate: Date) => {
    return new Date() < endDate;
  };

  const getRemainingTime = (endDate: Date) => {
    const now = new Date();
    const diff = endDate.getTime() - now.getTime();
    if (diff < 0) return 'Expired';
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}d ${hours}h ${minutes}m`;
  };

  const getVoterName = useCallback(async (userId: string | null) => {
    if (!userId) return "No voter";
    if (currentUser && (currentUser.uid === userId || isAdmin)) {
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          return userDoc.data().displayName || userId;
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    }
    return userId;
  }, [currentUser, isAdmin]);

  const VoterInfo: React.FC<{ voterId: string | null, voteType: 'favorabil' | 'nefavorabil' }> = ({ voterId, voteType }) => {
    const [voterName, setVoterName] = useState<string>("Loading...");

    useEffect(() => {
      getVoterName(voterId).then(setVoterName);
    }, [voterId]);

    return (
      <div className="mt-4">
        <h4 className="text-md font-medium">Votant {voteType === 'favorabil' ? 'Favorabil' : 'Nefavorabil'}:</h4>
        <p className="text-sm">{voterName}</p>
      </div>
    );
  };

  if (loading) {
    return <div className="text-center py-4">Se încarcă...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Sondaje</h1>

      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}

      {isAdmin && !isEditing && (
        <button 
          onClick={() => {
            setIsAddingPoll(!isAddingPoll);
            setIsEditing(false);
            setSelectedPoll(null);
            setFormData({ Nume: '', descriere: '', endDate: '' });
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
        >
          {isAddingPoll ? 'Anulează' : 'Adaugă Sondaj'}
        </button>
      )}

      {(isAddingPoll || isEditing) && (
        <form onSubmit={isEditing ? handleUpdatePoll : handleAddPoll} className="mb-6">
          <div className="mb-4">
            <label htmlFor="Nume" className="block text-gray-700 text-sm font-bold mb-2">Nume Sondaj</label>
            <input
              type="text"
              id="Nume"
              name="Nume"
              value={formData.Nume}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="descriere" className="block text-gray-700 text-sm font-bold mb-2">Descriere</label>
            <textarea
              id="descriere"
              name="descriere"
              value={formData.descriere}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="endDate" className="block text-gray-700 text-sm font-bold mb-2">Data Încheierii</label>
            <input
              type="datetime-local"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            {isEditing ? 'Actualizează Sondaj' : 'Adaugă Sondaj'}
          </button>
          {isEditing && (
            <button 
              type="button" 
              onClick={() => {
                setIsEditing(false);
                setSelectedPoll(null);
                setFormData({ Nume: '', descriere: '', endDate: '' });
              }} 
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-2"
            >
              Anulează
            </button>
          )}
        </form>
      )}

      {polls.length === 0 ? (
        <p className="text-center text-gray-600">Nu există Sondaje</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {polls.map((poll) => {
            const totalVotes = poll.favorabil + poll.nefavorabil;
            const favorabilPercentage = calculatePercentage(poll.favorabil, totalVotes);
            const nefavorabilPercentage = calculatePercentage(poll.nefavorabil, totalVotes);
            const isExpired = !isPollActive(poll.endDate);
            const favorabilWon = poll.favorabil > poll.nefavorabil;

            return (
              <div key={poll.id} className={`${
                isExpired ? 'bg-gray-200 text-gray-700' : 'bg-white'
              } shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-lg p-6 border ${
                isExpired ? 'border-gray-400' : 'border-gray-200'
              } relative`}>
                {isExpired && (
                  <div className="absolute top-2 right-2">
                    <div className={`
                      w-24 h-24 flex items-center justify-center
                      ${favorabilWon ? 'text-green-600' : 'text-red-600'}
                      border-4 border-dashed rounded-full
                    `}>
                      <div className={`
                        w-20 h-20 flex items-center justify-center
                        border-2 ${favorabilWon ? 'border-green-600' : 'border-red-600'} rounded-full
                      `}>
                        <span className="font-bold text-lg uppercase">
                          {favorabilWon ? 'Votat' : 'Respins'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <h2 className="text-xl font-semibold mb-4">{poll.Nume}</h2>
                <p className={`${isExpired ? 'text-gray-700' : 'text-gray-600'} mb-4`}>{poll.descriere}</p>

                <div className="mb-4">
                  <div className="flex mb-1">
                    <div 
                      style={{width: `${favorabilPercentage}%`}} 
                      className="bg-green-500 h-4 rounded-l"
                    ></div>
                    <div 
                      style={{width: `${nefavorabilPercentage}%`}} 
                      className="bg-red-500 h-4 rounded-r"
                    ></div>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span>Favorabil: {favorabilPercentage}% ({poll.favorabil})</span>
                    <span>Nefavorabil: {nefavorabilPercentage}% ({poll.nefavorabil})</span>
                  </div>
                </div>

                <div className="text-sm text-gray-500">
                  <div>Creat la: {poll.creationDate.toLocaleDateString()}</div>
                  <div>Se încheie la: {poll.endDate.toLocaleDateString()}</div>
                  <div>Status: {!isExpired ? 
                      <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">Activ</span> : 
                      <span className="bg-gray-300 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">Expirat</span>}
                    </div>
                    <div>Timp rămas: {getRemainingTime(poll.endDate)}</div>
                    <div>Total voturi: {totalVotes}</div>
                    </div>
                    <div className="mt-4">
                    <button onClick={() => setSelectedPoll(poll)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2">
                      Vezi detalii
                    </button>
                    {isAdmin && (
                      <>
                        <button 
                          onClick={() => {
                            if (!isExpired) {
                              setIsEditing(true);
                              setSelectedPoll(poll);
                              setFormData({
                                Nume: poll.Nume,
                                descriere: poll.descriere,
                                endDate: poll.endDate.toISOString().slice(0, 16),
                              });
                            }
                          }} 
                          className={`${
                            isExpired 
                              ? 'bg-gray-400 cursor-not-allowed' 
                              : 'bg-yellow-500 hover:bg-yellow-700'
                          } text-white font-bold py-1 px-2 rounded mr-2`}
                          disabled={isExpired}
                        >
                          Editează
                        </button>
                        <button onClick={() => handleDeletePoll(poll.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                          Șterge
                        </button>
                      </>
                    )}
                    </div>
                    </div>
                    );
                    })}
                    </div>
                    )}

                    {selectedPoll && !isEditing && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                    <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{selectedPoll.Nume}</h3>
                    <div className="mt-2 px-7 py-3">
                    <p className="text-sm text-gray-500">{selectedPoll.descriere}</p>
                    <p className="text-sm text-gray-500 mt-2">Timp rămas: {getRemainingTime(selectedPoll.endDate)}</p>
                    <p className="text-sm text-gray-500">Total voturi: {selectedPoll.favorabil + selectedPoll.nefavorabil}</p>
                    {selectedPoll.voter && (
                    <VoterInfo 
                      voterId={selectedPoll.voter} 
                      voteType={selectedPoll.favorabil > 0 ? 'favorabil' : 'nefavorabil'} 
                    />
                    )}
                    </div>
                    <div className="items-center px-4 py-3">
                    <button
                    id="ok-btn"
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    onClick={() => setSelectedPoll(null)}
                    >
                    Close
                    </button>
                    </div>
                    </div>
                    </div>
                    </div>
                    )}
                    </div>
                    );
                    };

                    export default Sondaje;