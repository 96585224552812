import React, { useState, useEffect, useRef } from 'react';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs, query, Timestamp, doc, getDoc, onSnapshot, orderBy, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList
} from 'recharts';
import Members from './members';
import Sponsors from './sponsors';
import Invoices from './invoices';
import Donations from './donations';
import Ajutoare from './ajutoare';
import Profile from './profile';
import PropPrimite from './propprimite';
import { Sondaje } from './sondaje';

interface Stats {
  totalVenituri: number;
  totalDonatii: number;
  totalExpenses: number;
  profit: number;
  totalMembers: number;
  totalActiveSponsors: number;
  totalInvoices: number;
  totalDonations: number;
}

interface ChartData {
  month: string;
  value: number;
}

interface SidebarItem {
  path: string;
  label: string;
  icon: string;
  unreadCount?: number;
}

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

interface DatePickerProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  placeholder: string;
  isDarkTheme: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({ selected, onChange, placeholder, isDarkTheme }) => {
  const [inputValue, setInputValue] = useState(selected ? selected.toISOString().split('T')[0] : '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    const date = new Date(e.target.value);
    onChange(isNaN(date.getTime()) ? null : date);
  };

  return (
    <div className="relative w-full max-w-sm">
      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
        <svg className={`w-4 h-4 ${isDarkTheme ? 'text-gray-400' : 'text-gray-600'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
        </svg>
      </div>
      <input
        type="date"
        className={`border text-sm rounded-lg block w-full ps-10 p-2.5 ${
          isDarkTheme
            ? 'bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500'
            : 'bg-white border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500'
        }`}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
      />
    </div>
  );
};

const AdminDashboard: React.FC = () => {
  const [stats, setStats] = useState<Stats>({
    totalVenituri: 0,
    totalDonatii: 0,
    totalExpenses: 0,
    profit: 0,
    totalMembers: 0,
    totalActiveSponsors: 0,
    totalInvoices: 0,
    totalDonations: 0,
  });
  const [revenueData, setRevenueData] = useState<ChartData[]>([]);
  const [membersData, setMembersData] = useState<ChartData[]>([]);
  const [sponsorsData, setSponsorsData] = useState<ChartData[]>([]);
  const [invoicesData, setInvoicesData] = useState<ChartData[]>([]);
  const [donationsData, setDonationsData] = useState<ChartData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("financiar");
  const [activeInternSubTab, setActiveInternSubTab] = useState("members");
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [user, setUser] = useState<any>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          setFirstName(userDoc.data()['first name'] || "");
        }

        const unsubscribeMessages = onSnapshot(
          query(collection(db, "propuneri")),
          (snapshot) => {
            const unreadCount = snapshot.docs.filter(doc => !doc.data().read).length;
            setUnreadMessages(unreadCount);
          }
        );

        return () => {
          unsubscribeMessages();
        };
      } else {
        navigate("/login");
      }
    });

    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node) && window.innerWidth < 768) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      unsubscribeAuth();
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navigate]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchStats();
    }
  }, [startDate, endDate]);

  const processMonthlyData = (snapshot: any, dateField: string = 'date'): ChartData[] => {
    const monthlyData = monthNames.map(month => ({ month, value: 0 }));
    snapshot.forEach((doc: any) => {
      const data = doc.data();
      if (data[dateField]) {
        let date: Date | null = null;
        if (data[dateField] instanceof Timestamp) {
          date = data[dateField].toDate();
        } else if (typeof data[dateField] === 'string') {
          date = new Date(data[dateField]);
        } else {
          console.warn(`Document ${doc.id} has invalid ${dateField} field`);
          return;
        }
        if (startDate && endDate && date && date >= startDate && date <= endDate) {
          const monthIndex = date.getMonth();
          monthlyData[monthIndex].value += 1;
        }
      } else {
        console.warn(`Document ${doc.id} has missing ${dateField} field`);
      }
    });
    return monthlyData;
  };

  const fetchStats = async () => {
    if (!startDate || !endDate) return;
    setIsLoading(true);
    setError(null);
    try {
      const membersQuery = query(collection(db, "members"), where("joinDate", ">=", startDate), where("joinDate", "<=", endDate));
      const sponsorsQuery = query(collection(db, "sponsors"), where("creationdate", ">=", startDate), where("creationdate", "<=", endDate));
      const invoicesQuery = query(collection(db, "invoices"), where("date", ">=", startDate), where("date", "<=", endDate));
      const donationsQuery = query(collection(db, "donations"), where("date", ">=", startDate), where("date", "<=", endDate));

      const [membersSnapshot, sponsorsSnapshot, invoicesSnapshot, donationsSnapshot] =
        await Promise.all([
          getDocs(membersQuery),
          getDocs(sponsorsQuery),
          getDocs(invoicesQuery),
          getDocs(donationsQuery),
        ]);

      const activeSponsors = sponsorsSnapshot.docs.filter(doc => doc.data().status === "Active");

      const totalVenituri = donationsSnapshot.docs.reduce((sum, doc) => sum + doc.data().amount, 0);
      const totalDonatii = totalVenituri;
      const totalExpenses = invoicesSnapshot.docs.reduce((sum, doc) => sum + doc.data().amount, 0);
      const profit = totalVenituri - totalExpenses;

      const newStats = {
        totalVenituri,
        totalDonatii,
        totalExpenses,
        profit,
        totalMembers: membersSnapshot.size,
        totalActiveSponsors: activeSponsors.length,
        totalInvoices: invoicesSnapshot.size,
        totalDonations: donationsSnapshot.size,
      };

      setStats(newStats);

      setMembersData(processMonthlyData(membersSnapshot, 'joinDate'));
      setSponsorsData(processMonthlyData(sponsorsSnapshot, 'creationdate'));
      setInvoicesData(processMonthlyData(invoicesSnapshot));
      setDonationsData(processMonthlyData(donationsSnapshot));

      const revenueMonthlyData = monthNames.map(month => ({ month, value: 0 }));
      donationsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.date) {
          const date = data.date.toDate();
          if (date >= startDate && date <= endDate) {
            const monthIndex = date.getMonth();
            revenueMonthlyData[monthIndex].value += data.amount;
          }
        }
      });
      setRevenueData(revenueMonthlyData);

    } catch (err) {
      console.error("Error fetching stats:", err);
      setError("Failed to fetch dashboard data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      setError("Failed to sign out. Please try again.");
    }
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebarOnMobile = () => {
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }
  };

  const FinancialCard: React.FC<{ title: string; value: string | number; trend?: number }> = ({ title, value, trend }) => (
    <div className={`p-4 rounded-lg shadow-md ${isDarkTheme ? 'bg-gray-800' : 'bg-white'}`}>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div className="text-2xl font-bold">{value}</div>
      {trend !== undefined && (
        <div className="mt-2 flex items-center">
          <svg
            className={`w-4 h-4 mr-1 ${trend >= 0 ? 'text-green-500' : 'text-red-500'}`}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d={trend >= 0 ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}></path>
          </svg>
          <span className={trend >= 0 ? "text-green-500" : "text-red-500"}>
            {trend >= 0 ? "+" : ""}{trend}%
          </span>
        </div>
      )}
    </div>
  );

  const ChartComponent: React.FC<{ title: string; data: ChartData[] }> = ({ title, data }) => (
    <div className={`p-4 rounded-lg shadow-md ${isDarkTheme ? 'bg-gray-800' : 'bg-white'}`}>
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8">
            <LabelList dataKey="value" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  const DashboardContent: React.FC = () => (
    <div className={`max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 ${
      isDarkTheme ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"
    }`}>
      <h1 className="text-3xl font-bold mb-6 text-center sm:text-left">Admin Dashboard</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4 flex flex-col items-center sm:flex-row sm:items-start">
        <DatePicker
          selected={startDate}
          onChange={setStartDate}
          placeholder="Start Date"
          isDarkTheme={isDarkTheme}
        />
        <DatePicker
          selected={endDate}
          onChange={setEndDate}
                  placeholder="End Date"
                  isDarkTheme={isDarkTheme}
                />
                <button
                  onClick={fetchStats}
                  className="w-full max-w-sm px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 sm:w-auto mt-2 sm:mt-0 sm:ml-2"
                >
                  Update
                </button>
              </div>

              <div className="mb-4">
                <div className="flex border-b overflow-x-auto">
                  <TabButton label="Financiar" isActive={activeTab === 'financiar'} onClick={() => setActiveTab('financiar')} />
                  <TabButton label="Intern" isActive={activeTab === 'intern'} onClick={() => setActiveTab('intern')} />
                </div>
              </div>

              {activeTab === 'financiar' && (
                <>
                  <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-6">
                    <FinancialCard title="Total Venituri" value={`${stats.totalVenituri.toLocaleString()} RON`} trend={2.5} />
                    <FinancialCard title="Total Donații" value={`${stats.totalDonatii.toLocaleString()} RON`} trend={1.8} />
                    <FinancialCard title="Cheltuieli" value={`${stats.totalExpenses.toLocaleString()} RON`} trend={0.5} />
                    <FinancialCard title="Profit" value={`${stats.profit.toLocaleString()} RON`} trend={3.2} />
                  </div>
                  <ChartComponent title="Grafic Venituri" data={revenueData} />
                </>
              )}

              {activeTab === 'intern' && (
                <>
                  <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-6">
                    <FinancialCard title="Total Members" value={stats.totalMembers.toString()} trend={1.5} />
                    <FinancialCard title="Active Sponsors" value={stats.totalActiveSponsors.toString()} trend={2.0} />
                    <FinancialCard title="Total Invoices" value={stats.totalInvoices.toString()} trend={0.8} />
                    <FinancialCard title="Total Donations" value={stats.totalDonations.toString()} trend={3.2} />
                  </div>
                  <div className="mb-4">
                    <div className="flex border-b overflow-x-auto">
                      <TabButton label="Members" isActive={activeInternSubTab === 'members'} onClick={() => setActiveInternSubTab('members')} />
                      <TabButton label="Sponsors" isActive={activeInternSubTab === 'sponsors'} onClick={() => setActiveInternSubTab('sponsors')} />
                      <TabButton label="Invoices" isActive={activeInternSubTab === 'invoices'} onClick={() => setActiveInternSubTab('invoices')} />
                      <TabButton label="Donations" isActive={activeInternSubTab === 'donations'} onClick={() => setActiveInternSubTab('donations')} />
                    </div>
                  </div>
                  {activeInternSubTab === 'members' && <ChartComponent title="Members Chart" data={membersData} />}
                  {activeInternSubTab === 'sponsors' && <ChartComponent title="Sponsors Chart" data={sponsorsData} />}
                  {activeInternSubTab === 'invoices' && <ChartComponent title="Invoices Chart" data={invoicesData} />}
                  {activeInternSubTab === 'donations' && <ChartComponent title="Donations Chart" data={donationsData} />}
                </>
              )}
            </div>
          );

          const TabButton: React.FC<{ label: string; isActive: boolean; onClick: () => void }> = ({ label, isActive, onClick }) => (
            <button
              className={`px-4 py-2 font-medium text-sm leading-5 rounded-t-lg focus:outline-none ${
                isActive
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={onClick}
            >
              {label}
            </button>
          );

          const sidebarItems: SidebarItem[] = [
            { path: "/admindashboard", label: "Dashboard", icon: "M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" },
            { path: "/admindashboard/members", label: "Membri", icon: "M12 12.75c1.63 0 3.07.39 4.24.9 1.08.48 1.76 1.56 1.76 2.73V18H6v-1.61c0-1.18.68-2.26 1.76-2.73 1.17-.52 2.61-.91 4.24-.91zM4 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm1.13 1.1c-.37-.06-.74-.1-1.13-.1-.99 0-1.93.21-2.78.58A2.01 2.01 0 0 0 0 16.43V18h4.5v-1.61c0-.83.23-1.61.63-2.29zM20 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm1.13 1.1c.37-.06.74-.1 1.13-.1.99 0 1.93.21 2.78.58.86.37 1.96.93 1.96 1.85V18h-4.5v-1.61c0-.83-.23-1.61-.63-2.29zM12 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3z" },
            { path: "/admindashboard/donations", label: "Donații", icon: "M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z" },
            { path: "/admindashboard/invoices", label: "Facturi", icon: "M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" },
            { path: "/admindashboard/sponsors", label: "Sponsori", icon: "M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" },
            { path: "/admindashboard/ajutoare", label: "Ajutoare", icon: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z" },
            { path: "/admindashboard/propuneri", label: "Propuneri", icon: "M21 14.58c0-.36-.19-.69-.49-.89L13 9V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-7.51 4.69c-.3.19-.49.53-.49.89 0 .7.68 1.21 1.36 1L10 13.5V19l-1.8 1.35c-.13.09-.2.24-.2.4v.59c0 .33.32.57.64.48L11.5 21l2.86.82c.32.09.64-.15.64-.48v-.59c0-.16-.07-.31-.2-.4L13 19v-5.5l6.64 2.08c.68.21 1.36-.3 1.36-1z", unreadCount: unreadMessages },
            { path: "/admindashboard/sondaje", label: "Sondaje", icon: "M17 2H7c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 14H8v-2h8v2zm0-4H8v-2h8v2zm0-4H8V6h8v2z" },
            { path: "/admindashboard/profile", label: "Profil", icon: "M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z" },
          ];

          return (
            <div className={`flex h-screen ${
              isDarkTheme ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"
            }`}>
              <div
                ref={sidebarRef}
                className={`${
                  isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                } md:translate-x-0 transform transition-transform duration-300 ease-in-out fixed md:static top-0 left-0 h-full w-64 ${
                  isDarkTheme ? "bg-gray-800" : "bg-green-600"
                } shadow-md z-40 flex flex-col overflow-y-auto`}
              >
                <div className="flex-shrink-0 p-4 hidden md:block">
                  <img
                    src="/FreeWayWhite.png"
                    alt="Logo"
                    className="w-2/3 mx-auto"
                  />
                  </div>
                  <div className="w-full h-px bg-white opacity-50 my-2 hidden md:block"></div>
                  <nav className="flex-grow mt-2">
                  {sidebarItems.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`flex items-center py-3 px-4 my-1 font-inter font-medium text-base md:text-sm ${
                        isDarkTheme
                          ? "text-white hover:bg-gray-700"
                          : "text-white hover:bg-green-700"
                      } ${
                        location.pathname === item.path
                          ? isDarkTheme
                            ? "bg-gray-700"
                            : "bg-green-700"
                          : ""
                      }`}
                      onClick={closeSidebarOnMobile}
                    >
                      <div className="w-7 h-7 mr-3 bg-white bg-opacity-20 rounded-md flex items-center justify-center">
                        <svg
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4"
                        >
                          <path d={item.icon} />
                        </svg>
                      </div>
                      {item.label}
                      {item.unreadCount !== undefined && item.unreadCount > 0 && (
                        <span className="ml-2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
                          {item.unreadCount}
                        </span>
                      )}
                    </Link>
                  ))}
                  </nav>
                  <div className="p-4">
                  <div className="flex items-center justify-between mb-4">
                    <span className="font-inter font-medium text-sm text-white">Dark Theme</span>
                    <div
                      className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
                        isDarkTheme ? 'bg-green-500' : 'bg-gray-300'
                      }`}
                      onClick={toggleTheme}
                    >
                      <div
                        className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                          isDarkTheme ? 'translate-x-6' : ''
                        }`}
                      ></div>
                    </div>
                  </div>
                  <div className="text-white font-medium text-sm mb-4">
                    Hello, {firstName}
                  </div>
                  <button
                    onClick={handleSignOut}
                    className="w-full py-2 px-4 bg-red-600 text-white text-sm rounded hover:bg-red-700 font-inter font-medium flex items-center justify-center"
                  >
                    Logout
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
                    </svg>
                  </button>
                  </div>
                  </div>

                  <div ref={mainContentRef} className="flex-1 overflow-y-auto">
                  <button
                  className="md:hidden fixed top-4 right-4 z-50 p-2 rounded-md bg-white shadow-md"
                  onClick={toggleSidebar}
                  >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  </button>
                  <Routes>
                  <Route index element={<DashboardContent />} />
                  <Route path="members" element={<Members />} />
                  <Route path="donations" element={<Donations />} />
                  <Route path="invoices" element={<Invoices />} />
                  <Route path="sponsors" element={<Sponsors />} />
                  <Route path="ajutoare" element={<Ajutoare />} />
                  <Route path="propuneri" element={<PropPrimite />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="sondaje" element={<Sondaje />} />
                  </Routes>
                  </div>
                  </div>
                  );
                  };

                  export default AdminDashboard;