import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
  orderBy,
  limit,
  startAfter,
  where,
  Query,
  DocumentData,
} from "firebase/firestore";
import { db } from "../firebase";
import { ChevronLeft, ChevronRight, ArrowUp, ArrowDown, Edit, Trash2 } from "lucide-react";

interface Member {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  joinDate: Timestamp;
}

const ITEMS_PER_PAGE = 20;

const Members: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [newMember, setNewMember] = useState<Omit<Member, "id" | "joinDate">>({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [editingMember, setEditingMember] = useState<Member | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ isOpen: boolean; memberId: string | null }>({
    isOpen: false,
    memberId: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<"last_name" | "first_name">("last_name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [totalMembers, setTotalMembers] = useState(0);

  useEffect(() => {
    fetchMembers();
    fetchTotalMembers();
  }, [sortField, sortDirection, currentPage, searchTerm]);

  const fetchTotalMembers = async () => {
    try {
      const snapshot = await getDocs(collection(db, "members"));
      setTotalMembers(snapshot.size);
    } catch (err) {
      console.error("Error fetching total members:", err);
    }
  };

  const fetchMembers = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let membersQuery: Query<DocumentData> = collection(db, "members");

      // Apply sorting
      membersQuery = query(membersQuery, orderBy(sortField, sortDirection));

      // Apply pagination
      if (currentPage > 1 && lastVisible) {
        membersQuery = query(membersQuery, startAfter(lastVisible));
      }

      membersQuery = query(membersQuery, limit(ITEMS_PER_PAGE));

      const membersSnapshot = await getDocs(membersQuery);

      if (!membersSnapshot.empty) {
        setLastVisible(membersSnapshot.docs[membersSnapshot.docs.length - 1]);
      }

      let membersList = membersSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          joinDate: data.joinDate instanceof Timestamp ? data.joinDate : Timestamp.now(),
        } as Member;
      });

      // Apply case-insensitive filtering on the client side
      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        membersList = membersList.filter(member => 
          member.last_name.toLowerCase().includes(lowerSearchTerm) ||
          member.first_name.toLowerCase().includes(lowerSearchTerm) ||
          member.email.toLowerCase().includes(lowerSearchTerm)
        );
      }

      setMembers(membersList);
    } catch (err) {
      console.error("Error fetching members:", err);
      setError("Failed to fetch members. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addMember = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const memberWithJoinDate = {
        ...newMember,
        joinDate: Timestamp.now(),
      };
      const docRef = await addDoc(collection(db, "members"), memberWithJoinDate);
      const newMemberWithId = { ...memberWithJoinDate, id: docRef.id };
      setMembers([...members, newMemberWithId]);
      setNewMember({
        email: "",
        first_name: "",
        last_name: "",
      });
      setIsAddingNew(false);
      setTotalMembers(prev => prev + 1);
    } catch (err) {
      console.error("Error adding new member:", err);
      setError("Failed to add new member. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateMember = async () => {
    if (!editingMember) return;

    setIsLoading(true);
    setError(null);
    try {
      const { id, ...updatedData } = editingMember;
      await updateDoc(doc(db, "members", id), updatedData);
      setMembers(
        members.map((member) =>
          member.id === id ? editingMember : member
        )
      );
      setEditingMember(null);
    } catch (err) {
      console.error("Error updating member:", err);
      setError("Failed to update member. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = (id: string) => {
    setDeleteConfirmation({ isOpen: true, memberId: id });
  };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, memberId: null });
  };

  const deleteMember = async () => {
    if (!deleteConfirmation.memberId) return;

    setIsLoading(true);
    setError(null);
    try {
      await deleteDoc(doc(db, "members", deleteConfirmation.memberId));
      setMembers(members.filter((member) => member.id !== deleteConfirmation.memberId));
      setDeleteConfirmation({ isOpen: false, memberId: null });
      setTotalMembers(prev => prev - 1);
    } catch (err) {
      console.error("Error deleting member:", err);
      setError("Failed to delete member. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handleSort = (field: "last_name" | "first_name") => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setLastVisible(null);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + members.length - 1, totalMembers);

  return (
    <div className="p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Members</h1>
      <div className="mb-4">
        <button
          className="bg-[#93C572] hover:bg-[#7ba35e] text-white px-4 py-2 rounded transition duration-300 ease-in-out"
          onClick={() => setIsAddingNew(true)}
          disabled={isLoading}
        >
          Add New Member
        </button>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4 flex justify-between items-center">
        <div className="flex-1 mr-4">
          <input
            type="text"
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={handleSearch}
            className="p-2 border border-border rounded w-full bg-input text-text"
          />
        </div>
      </div>
      {isAddingNew && (
        <div className="mb-4 p-4 border border-border rounded bg-form">
          <input
            type="email"
            placeholder="Email"
            value={newMember.email}
            onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <input
            type="text"
            placeholder="First Name"
            value={newMember.first_name}
            onChange={(e) => setNewMember({ ...newMember, first_name: e.target.value })}
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <input
            type="text"
            placeholder="Last Name"
            value={newMember.last_name}
            onChange={(e) => setNewMember({ ...newMember, last_name: e.target.value })}
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
            onClick={addMember}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
            onClick={() => setIsAddingNew(false)}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-table-header">
              <th className="border border-border p-2 text-left">Nr.</th>
              <th className="border border-border p-2 text-left">Email</th>
              <th 
                className="border border-border p-2 text-left cursor-pointer"
                onClick={() => handleSort("first_name")}
              >
                First Name
                {sortField === "first_name" && (
                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                )}
              </th>
              <th 
                className="border border-border p-2 text-left cursor-pointer"
                onClick={() => handleSort("last_name")}
              >
                Last Name
                {sortField === "last_name" && (
                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                )}
              </th>
              <th className="border border-border p-2 text-left">Join Date</th>
              <th className="border border-border p-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={member.id} className="bg-table-row hover:bg-table-row-hover">
                <td className="border border-border p-2">{startIndex + index}</td>
                <td className="border border-border p-2">
                  {editingMember?.id === member.id ? (
                    <input
                      type="email"
                      value={editingMember.email}
                      onChange={(e) => setEditingMember({ ...editingMember, email: e.target.value })}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    member.email
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingMember?.id === member.id ? (
                    <input
                      type="text"
                      value={editingMember.first_name}
                      onChange={(e) => setEditingMember({ ...editingMember, first_name: e.target.value })}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    member.first_name
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingMember?.id === member.id ? (
                    <input
                      type="text"
                      value={editingMember.last_name}
                      onChange={(e) => setEditingMember({ ...editingMember, last_name: e.target.value })}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    member.last_name
                  )}
                </td>
                <td className="border border-border p-2">
                  {member.joinDate instanceof Timestamp
                    ? formatDate(member.joinDate.toDate())
                    : "N/A"}
                </td>
                <td className="border border-border p-2">
                  {editingMember?.id === member.id ? (
                    <>
                      <button
                        className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded mr-2 transition duration-300 ease-in-out"
                        onClick={updateMember}
                        disabled={isLoading}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-600 text-white px-2 py-1 rounded transition duration-300 ease-in-out"
                        onClick={() => setEditingMember(null)}
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <div className="flex space-x-2">
                      <button
                        className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                                onClick={() => setEditingMember(member)}
                                                disabled={isLoading}
                                              >
                                                <Edit className="w-4 h-4 mr-1" /> Edit
                                              </button>
                                              <button
                                                className="bg-[#DC3545] hover:bg-[#c82333] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                                onClick={() => confirmDelete(member.id)}
                                                disabled={isLoading}
                                              >
                                                <Trash2 className="w-4 h-4 mr-1" /> Delete
                                              </button>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="mt-8 flex justify-between items-center">
                                <span className="text-sm">
                                  Showing {startIndex}-{endIndex} of {totalMembers}
                                </span>
                                <div className="flex-1 flex justify-center items-center space-x-2">
                                  <button
                                    className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1 || isLoading}
                                  >
                                    <ChevronLeft className="h-5 w-5" />
                                  </button>
                                  <span className="text-sm">
                                    {currentPage} of {Math.ceil(totalMembers / ITEMS_PER_PAGE)}
                                  </span>
                                  <button
                                    className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                    onClick={handleNextPage}
                                    disabled={endIndex >= totalMembers || isLoading}
                                  >
                                    <ChevronRight className="h-5 w-5" />
                                  </button>
                                </div>
                                <div className="w-[150px]"></div> {/* This empty div balances the layout */}
                              </div>

                              {deleteConfirmation.isOpen && (
                                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                  <div className="bg-white p-6 rounded-lg">
                                    <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                                    <p className="mb-4">Are you sure you want to delete this member?</p>
                                    <div className="flex justify-end">
                                      <button
                                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
                                        onClick={cancelDelete}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                                        onClick={deleteMember}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        };

                        export default Members;