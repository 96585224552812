import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCW7mu1PPa4jQZPAVRPRGZps7M8VPcQFSc",
  authDomain: "web-app-booking.firebaseapp.com",
  projectId: "web-app-booking",
  storageBucket: "web-app-booking.appspot.com",
  messagingSenderId: "385889464436",
  appId: "1:385889464436:web:c4d6e1d641c480b8676121"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage, analytics };