import React, { useState, useEffect, useRef } from 'react';
import OpenAI from 'openai';
import { ChatCompletionMessageParam } from 'openai/resources/chat';

let openai: OpenAI | null = null;
try {
  openai = new OpenAI({
    apiKey: import.meta.env.VITE_OPENAI_API_KEY || '',
    dangerouslyAllowBrowser: true // Note: This is not recommended for production. Use a backend proxy instead.
  });
} catch (error) {
  console.error("Failed to initialize OpenAI client:", error);
}

type Role = 'user' | 'assistant' | 'system';

interface Message {
  role: Role;
  content: string;
}

const Chatbot: React.FC = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const chatbotRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!import.meta.env.VITE_OPENAI_API_KEY) {
      console.error("OpenAI API key is missing");
      setError("OpenAI API key is missing. Please check your configuration.");
      setMessages([{
        role: 'assistant',
        content: 'Îmi pare rău, dar nu pot funcționa momentan din cauza unei erori de configurare.'
      }]);
    } else if (!openai) {
      console.error("OpenAI client failed to initialize");
      setError("Failed to initialize OpenAI client. Please try again later.");
      setMessages([{
        role: 'assistant',
        content: 'Îmi pare rău, dar am întâmpinat o problemă tehnică. Vă rugăm să încercați din nou mai târziu.'
      }]);
    } else {
      setMessages([{
        role: 'assistant',
        content: 'Bine ați venit la Asociația "Free Way"! Cu ce vă pot ajuta astăzi?'
      }]);
    }
  }, []);

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || !openai) return;

    const userMessage: Message = { role: 'user', content: input };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);
    setError(null);

    try {
      const systemMessage: ChatCompletionMessageParam = {
        role: 'system',
        content: `Ești un asistent virtual pentru Asociația "Free Way". Răspunde în limba română. Iată informațiile despre asociație:

Asociația Free Way este o organizație non-guvernamentală din România care se concentrează pe ajutor umanitar, asistență socială și inițiative ecologice. Misiunea lor este de a sprijini comunitățile defavorizate prin ajutor financiar, donații și organizarea de evenimente caritabile. De asemenea, desfășoară campanii ecologice, cum ar fi plantarea de copaci și creșterea conștientizării cu privire la problemele de mediu. Organizația pune accent pe implicarea comunității, oferind oportunități pentru colaborare, voluntariat și donații din partea persoanelor și companiilor.

Conducere:
Președinte - Brindușan Raul
Vicepreședinte - Osan Ionuț
Secretar - Pop Adrian
Membru - Brindușan Marius

Folosește aceste informații pentru a răspunde la întrebările utilizatorilor despre Asociația "Free Way".`
      };

      const apiMessages: ChatCompletionMessageParam[] = [
        systemMessage,
        ...messages.map(msg => ({ role: msg.role, content: msg.content })),
        { role: userMessage.role, content: userMessage.content }
      ];

      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: apiMessages,
      });

      const assistantMessage: Message = {
        role: 'assistant',
        content: response.choices[0].message?.content || 'Îmi pare rău, nu am putut genera un răspuns.',
      };
      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
        setError(`An error occurred: ${error.message}`);
      } else {
        setError("An unknown error occurred");
      }
      setMessages(prev => [...prev, { role: 'assistant', content: 'Îmi pare rău, a apărut o eroare în procesarea cererii dumneavoastră.' }]);
    }

    setIsLoading(false);
  };

  return (
    <div ref={chatbotRef} className="fixed bottom-9 right-4 flex flex-col bg-white rounded-lg shadow-xl w-80 h-[29rem]">
      <div className="bg-blue-500 text-white p-2 rounded-t-lg">
        <h3 className="font-bold text-center">Chatbot Asociația "Free Way"</h3>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((message, index) => (
          <div key={index} className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded-lg ${message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
              {message.content}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      {error && (
        <div className="p-2 bg-red-100 text-red-700 text-sm">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit} className="p-4 border-t">
        <div className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="flex-1 p-2 border rounded-l-lg"
            placeholder="Scrieți mesajul dvs..."
            disabled={isLoading || !openai}
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-r-lg hover:bg-blue-600 flex items-center justify-center"
            disabled={isLoading || !openai}
          >
            {isLoading ? (
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Chatbot;