import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, updateDoc, doc, orderBy, increment, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { User } from 'firebase/auth';

interface Poll {
  id: string;
  Nume: string;
  descriere: string;
  creationDate: Date;
  endDate: Date;
  favorabil: number;
  nefavorabil: number;
  voter: string | null;
}

export const SondajeUsers: React.FC = () => {
  const [polls, setPolls] = useState<Poll[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        console.log("User is authenticated:", currentUser.uid);
      } else {
        console.log("User is not authenticated");
      }
    });
    fetchPolls();
    return unsubscribe;
  }, []);

  const fetchPolls = async () => {
    setLoading(true);
    setError(null);
    try {
      const q = query(collection(db, 'sondaje'), orderBy('creationDate', 'desc'));
      const querySnapshot = await getDocs(q);
      const fetchedPolls: Poll[] = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        creationDate: doc.data().creationDate.toDate(),
        endDate: doc.data().endDate.toDate(),
        voter: doc.data().voter || null,
      } as Poll));
      setPolls(fetchedPolls);
    } catch (err) {
      console.error("Error fetching polls:", err);
      setError("An error occurred while fetching polls. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVote = async (pollId: string, vote: 'favorabil' | 'nefavorabil') => {
    if (!auth.currentUser) {
      setError("You must be logged in to vote.");
      return;
    }

    const poll = polls.find(p => p.id === pollId);
    if (!poll) {
      setError("Poll not found.");
      return;
    }

    if (!isPollActive(poll.endDate)) {
      setError("This poll has expired and can no longer be voted on.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const pollRef = doc(db, 'sondaje', pollId);
      const pollDoc = await getDoc(pollRef);
      if (!pollDoc.exists()) {
        throw new Error("Poll document does not exist");
      }
      const currentPollData = pollDoc.data() as Poll;

      if (currentPollData.voter === auth.currentUser.uid) {
        setError("You have already voted on this poll.");
        setLoading(false);
        return;
      }

      console.log("Adding new vote", { vote });

      const updateData = {
        [vote]: increment(1),
        voter: auth.currentUser.uid
      };

      await updateDoc(pollRef, updateData);

      console.log("Vote updated successfully");
      await fetchPolls(); // Refresh polls after voting
    } catch (err) {
      console.error("Error voting:", err);
      if (err instanceof Error) {
        setError(`An error occurred while voting: ${err.message}`);
      } else {
        setError("An unknown error occurred while voting. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const calculatePercentage = (value: number, total: number) => {
    if (total === 0) return 0;
    return ((value / total) * 100).toFixed(1);
  };

  const isPollActive = (endDate: Date) => {
    return new Date() < endDate;
  };

  const getRemainingTime = (endDate: Date) => {
    const now = new Date();
    const diff = endDate.getTime() - now.getTime();
    if (diff < 0) return 'Expired';
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}d ${hours}h ${minutes}m`;
  };

  const getPollOutcome = (poll: Poll) => {
    if (isPollActive(poll.endDate)) return null;
    return poll.favorabil > poll.nefavorabil ? 'Votat' : 'Respins';
  };

  if (loading) {
    return <div className="text-center py-4">Se încarcă...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Sondaje</h1>

      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}

      {polls.length === 0 ? (
        <p className="text-center text-gray-600">Nu există Sondaje</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {polls.map((poll) => {
            const totalVotes = poll.favorabil + poll.nefavorabil;
            const favorabilPercentage = calculatePercentage(poll.favorabil, totalVotes);
            const nefavorabilPercentage = calculatePercentage(poll.nefavorabil, totalVotes);
            const outcome = getPollOutcome(poll);
            const isExpired = !isPollActive(poll.endDate);
            const favorabilWon = poll.favorabil > poll.nefavorabil;

            return (
              <div key={poll.id} className={`${
                isExpired ? 'bg-gray-200 text-gray-700' : 'bg-white'
              } shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-lg p-6 border ${
                isExpired ? 'border-gray-400' : 'border-gray-200'
              } relative`}>
                {isExpired && (
                  <div className="absolute top-2 right-2">
                    <div className={`
                      w-24 h-24 flex items-center justify-center
                      ${favorabilWon ? 'text-green-600' : 'text-red-600'}
                      border-4 border-dashed rounded-full
                    `}>
                      <div className={`
                        w-20 h-20 flex items-center justify-center
                        border-2 ${favorabilWon ? 'border-green-600' : 'border-red-600'} rounded-full
                      `}>
                        <span className="font-bold text-lg uppercase">
                          {favorabilWon ? 'Votat' : 'Respins'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <h2 className="text-xl font-semibold mb-4">{poll.Nume}</h2>
                <p className={`${isExpired ? 'text-gray-700' : 'text-gray-600'} mb-4`}>{poll.descriere}</p>

                <div className="mb-4">
                  <div className="flex mb-1">
                    <div 
                      style={{width: `${favorabilPercentage}%`}} 
                      className="bg-green-500 h-4 rounded-l"
                    ></div>
                    <div 
                      style={{width: `${nefavorabilPercentage}%`}} 
                      className="bg-red-500 h-4 rounded-r"
                    ></div>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span>Favorabil: {favorabilPercentage}% ({poll.favorabil})</span>
                    <span>Nefavorabil: {nefavorabilPercentage}% ({poll.nefavorabil})</span>
                  </div>
                </div>

                <div className="text-sm text-gray-500">
                  <div>Creat la: {poll.creationDate.toLocaleDateString()}</div>
                  <div>Se încheie la: {poll.endDate.toLocaleDateString()}</div>
                  <div>Status: {!isExpired ? 
                    <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">Activ</span> : 
                    <span className="bg-gray-300 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">Expirat</span>}
                  </div>
                  <div>Timp rămas: {getRemainingTime(poll.endDate)}</div>
                  <div>Total voturi: {totalVotes}</div>
                </div>
                {!isExpired && auth.currentUser && poll.voter !== auth.currentUser.uid && (
                  <div className="mt-4">
                    <button 
                      onClick={() => handleVote(poll.id, 'favorabil')}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2 transition-colors duration-300"
                      disabled={loading}
                    >
                      Votează Favorabil
                    </button>
                    <button 
                      onClick={() => handleVote(poll.id, 'nefavorabil')}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                      disabled={loading}
                    >
                      Votează Nefavorabil
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SondajeUsers;