import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  Timestamp,
  updateDoc,
  orderBy,
  limit,
  startAfter,
  Query,
  DocumentData,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../firebase";
import { ChevronLeft, ChevronRight, ArrowUp, ArrowDown, Edit, Trash2 } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Donation {
  id: string;
  amount: number;
  date: Timestamp;
  donor: string;
  contractNr: string;
  fileUrl?: string;
  fileName?: string;
  observatii: string;
}

const ITEMS_PER_PAGE = 50;

const Donations: React.FC = () => {
  const [donations, setDonations] = useState<Donation[]>([]);
  const [newDonation, setNewDonation] = useState<Omit<Donation, "id">>({
    amount: 0,
    date: Timestamp.now(),
    donor: "",
    contractNr: "",
    observatii: "",
  });
  const [editingDonation, setEditingDonation] = useState<Donation | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ isOpen: boolean; donationId: string | null }>({
    isOpen: false,
    donationId: null,
  });
  const [filePreview, setFilePreview] = useState<{ isOpen: boolean; url: string; type: string }>({
    isOpen: false,
    url: "",
    type: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<"donor" | "date">("donor");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [totalDonations, setTotalDonations] = useState(0);

  useEffect(() => {
    fetchDonations();
    fetchTotalDonations();
  }, [sortField, sortDirection, currentPage, searchTerm]);

  const fetchTotalDonations = async () => {
    try {
      const snapshot = await getDocs(collection(db, "donations"));
      setTotalDonations(snapshot.size);
    } catch (err) {
      console.error("Error fetching total donations:", err);
    }
  };

  const fetchDonations = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let donationsQuery: Query<DocumentData> = collection(db, "donations");

      donationsQuery = query(donationsQuery, orderBy(sortField, sortDirection));

      if (currentPage > 1 && lastVisible) {
        donationsQuery = query(donationsQuery, startAfter(lastVisible));
      }

      donationsQuery = query(donationsQuery, limit(ITEMS_PER_PAGE));

      const donationsSnapshot = await getDocs(donationsQuery);

      if (!donationsSnapshot.empty) {
        setLastVisible(donationsSnapshot.docs[donationsSnapshot.docs.length - 1]);
      }

      let donationsList = await Promise.all(donationsSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          amount: Number(data.amount),
          date: data.date,
          donor: data.donor,
          contractNr: data.contractNr,
          fileUrl: data.fileUrl,
          fileName: data.fileName,
          observatii: data.observatii || "",
        } as Donation;
      }));

      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        donationsList = donationsList.filter(donation => 
          donation.donor.toLowerCase().includes(lowerSearchTerm) ||
          donation.contractNr.toLowerCase().includes(lowerSearchTerm)
        );
      }

      setDonations(donationsList);
    } catch (err) {
      console.error("Error fetching donations:", err);
      setError("Failed to fetch donations. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addDonation = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let fileUrl = "";
      let fileName = "";
      if (file) {
        const storageRef = ref(storage, `donation_files/${file.name}`);
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
        fileName = file.name;
      }

      const donationWithDate = {
        ...newDonation,
        amount: Number(newDonation.amount),
        date: Timestamp.fromDate(newDonation.date.toDate()),
        fileUrl,
        fileName,
      };
      const docRef = await addDoc(collection(db, "donations"), donationWithDate);
      const newDonationWithId = { ...donationWithDate, id: docRef.id };
      setDonations([...donations, newDonationWithId]);
      setNewDonation({
        amount: 0,
        date: Timestamp.now(),
        donor: "",
        contractNr: "",
        observatii: "",
      });
      setFile(null);
      setIsAddingNew(false);
      setTotalDonations(prev => prev + 1);
    } catch (err) {
      console.error("Error adding new donation:", err);
      setError("Failed to add new donation. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateDonation = async () => {
    if (!editingDonation) return;

    setIsLoading(true);
    setError(null);
    try {
      let fileUrl = editingDonation.fileUrl;
      let fileName = editingDonation.fileName;

      if (file) {
        if (editingDonation.fileUrl) {
          const oldFileRef = ref(storage, editingDonation.fileUrl);
          await deleteObject(oldFileRef);
        }

        const storageRef = ref(storage, `donation_files/${file.name}`);
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
        fileName = file.name;
      }

      const updatedDonation = {
        ...editingDonation,
        amount: Number(editingDonation.amount),
        date: Timestamp.fromDate(editingDonation.date.toDate()),
        fileUrl,
        fileName,
      };

      await updateDoc(doc(db, "donations", editingDonation.id), updatedDonation);

      setDonations(donations.map(d => d.id === editingDonation.id ? updatedDonation : d));
      setEditingDonation(null);
      setFile(null);
    } catch (err) {
      console.error("Error updating donation:", err);
      setError("Failed to update donation. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = (id: string) => {
    setDeleteConfirmation({ isOpen: true, donationId: id });
  };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, donationId: null });
  };

  const deleteDonation = async () => {
    if (!deleteConfirmation.donationId) return;

    setIsLoading(true);
    setError(null);
    try {
      const donationToDelete = donations.find(d => d.id === deleteConfirmation.donationId);
      if (donationToDelete && donationToDelete.fileUrl) {
        const fileRef = ref(storage, donationToDelete.fileUrl);
        await deleteObject(fileRef);
      }
      await deleteDoc(doc(db, "donations", deleteConfirmation.donationId));
      setDonations(donations.filter((donation) => donation.id !== deleteConfirmation.donationId));
      setDeleteConfirmation({ isOpen: false, donationId: null });
      setTotalDonations(prev => prev - 1);
    } catch (err) {
      console.error("Error deleting donation:", err);
      setError("Failed to delete donation. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const openFilePreview = (url: string, fileName: string) => {
    const fileType = fileName.split('.').pop()?.toLowerCase();
    let type = "other";
    if (fileType === "pdf") {
      type = "pdf";
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileType || "")) {
      type = "image";
    }
    setFilePreview({ isOpen: true, url, type });
  };

  const closeFilePreview = () => {
    setFilePreview({ isOpen: false, url: "", type: "" });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handleSort = (field: "donor" | "date") => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setLastVisible(null);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setNewDonation({ ...newDonation, date: Timestamp.fromDate(date) });
    }
  };

  const handleEditDateChange = (date: Date | null) => {
    if (date && editingDonation) {
      setEditingDonation({ ...editingDonation, date: Timestamp.fromDate(date) });
    }
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + donations.length - 1, totalDonations);

  return (
    <div className="p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Donations</h1>
      <div className="mb-4">
        <button
          className="bg-[#93C572] hover:bg-[#7ba35e] text-white px-4 py-2 rounded transition duration-300 ease-in-out"
          onClick={() => setIsAddingNew(true)}
          disabled={isLoading}
        >
          Add New Donation
        </button>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4 flex justify-between items-center">
        <div className="flex-1 mr-4">
          <input
            type="text"
            placeholder="Search by donor name or contract number"
            value={searchTerm}
            onChange={handleSearch}
            className="p-2 border border-border rounded w-full bg-input text-text"
          />
        </div>
      </div>
      {isAddingNew && (
        <div className="mb-4 p-4 border border-border rounded bg-form">
          <input
            type="number"
            placeholder="Amount"
            value={newDonation.amount}
            onChange={(e) =>
              setNewDonation({ ...newDonation, amount: parseFloat(e.target.value) || 0 })
            }
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <div className="mb-2">
            <DatePicker
              selected={newDonation.date.toDate()}
              onChange={handleDateChange}
              className="p-2 border border-border rounded w-full bg-input text-text"
            />
          </div>
          <input
            type="text"
            placeholder="Donor"
            value={newDonation.donor}
            onChange={(e) =>
              setNewDonation({ ...newDonation, donor: e.target.value })
            }
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <input
            type="text"
            placeholder="Contract Nr."
            value={newDonation.contractNr}
            onChange={(e) =>
              setNewDonation({ ...newDonation, contractNr: e.target.value })
            }
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <input
            type="text"
            placeholder="Observations"
            value={newDonation.observatii}
            onChange={(e) =>
              setNewDonation({ ...newDonation, observatii: e.target.value })
            }
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <input
            type="file"
            onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
            onClick={addDonation}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
            onClick={() => setIsAddingNew(false)}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-table-header">
              <th className="border border-border p-2 text-left">Nr.</th>
              <th className="border border-border p-2 text-left">Amount</th>
              <th 
                className="border border-border p-2 text-left cursor-pointer"
                onClick={() => handleSort("date")}
              >
                Date
                {sortField === "date" && (
                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                )}
              </th>
              <th 
                className="border border-border p-2 text-left cursor-pointer"
                onClick={() => handleSort("donor")}
              >
                Donor
                {sortField === "donor" && (
                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                )}
              </th>
              <th className="border border-border p-2 text-left">Contract Nr.</th>
              <th className="border border-border p-2 text-left">File</th>
              <th className="border border-border p-2 text-left">Obs.</th>
              <th className="border border-border p-2 text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            {donations.map((donation, index) => (
              <tr key={donation.id} className="bg-table-row hover:bg-table-row-hover">
                <td className="border border-border p-2">{startIndex + index}</td>
                <td className="border border-border p-2">
                  {editingDonation?.id === donation.id ? (
                    <input
                      type="number"
                      value={editingDonation.amount}
                      onChange={(e) => setEditingDonation({ ...editingDonation, amount: parseFloat(e.target.value) || 0 })}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    `RON ${Number(donation.amount).toFixed(2)}`
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingDonation?.id === donation.id ? (
                    <DatePicker
                      selected={editingDonation.date.toDate()}
                      onChange={handleEditDateChange}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    formatDate(donation.date.toDate())
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingDonation?.id === donation.id ? (
                    <input
                      type="text"
                      value={editingDonation.donor}
                      onChange={(e) => setEditingDonation({ ...editingDonation, donor: e.target.value })}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    donation.donor
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingDonation?.id === donation.id ? (
                    <input
                      type="text"
                      value={editingDonation.contractNr}
                      onChange={(e) => setEditingDonation({ ...editingDonation, contractNr: e.target.value })}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    donation.contractNr
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingDonation?.id === donation.id ? (
                    <input
                      type="file"
                      onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                      className="w-full p-1 border rounded"
                    />
                  ) : donation.fileUrl ? (
                    <button
                      onClick={() => openFilePreview(donation.fileUrl!, donation.fileName!)}
                      className="text-blue-500 hover:underline"
                    >
                      View File
                    </button>
                  ) : (
                    "No file"
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingDonation?.id === donation.id ? (
                    <input
                      type="text"
                      value={editingDonation.observatii}
                      onChange={(e) => setEditingDonation({ ...editingDonation, observatii: e.target.value })}
                      className="w-full p-1 border rounded"
                    />
                  ) : (
                    donation.observatii
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingDonation?.id === donation.id ? (
                    <>
                      <button
                        className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded mr-2 transition duration-300 ease-in-out"
                        onClick={updateDonation}
                        disabled={isLoading}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-600 text-white px-2 py-1 rounded transition duration-300 ease-in-out"
                        onClick={() => setEditingDonation(null)}
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <div className="flex space-x-2">
                      <button
                        className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                        onClick={() => setEditingDonation(donation)}
                        disabled={isLoading}
                      >
                        <Edit className="w-4 h-4 mr-1" /> Edit
                      </button>
                      <button
                        className="bg-[#DC3545] hover:bg-[#c82333] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                        onClick={() => confirmDelete(donation.id)}
                        disabled={isLoading}
                      >
                        <Trash2 className="w-4 h-4 mr-1" /> Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            </tbody>
            </table>
            </div>
            <div className="mt-8 flex justify-between items-center">
            <span className="text-sm">
            Showing {startIndex}-{endIndex} of {totalDonations}
            </span>
            <div className="flex-1 flex justify-center items-center space-x-2">
            <button
            className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
            onClick={handlePrevPage}
            disabled={currentPage === 1 || isLoading}
            >
            <ChevronLeft className="h-5 w-5" />
            </button>
            <span className="text-sm">
            {currentPage} of {Math.ceil(totalDonations / ITEMS_PER_PAGE)}
            </span>
            <button
            className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
            onClick={handleNextPage}
            disabled={endIndex >= totalDonations || isLoading}
            >
            <ChevronRight className="h-5 w-5" />
            </button>
            </div>
            <div className="w-[150px]"></div> {/* This empty div balances the layout */}
            </div>

            {deleteConfirmation.isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-4">Are you sure you want to delete this donation?</p>
            <div className="flex justify-end">
              <button
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                onClick={deleteDonation}
              >
                Delete
              </button>
            </div>
            </div>
            </div>
            )}

            {filePreview.isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg w-3/4 h-3/4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">File Preview</h2>
              <button
                className="text-2xl"
                onClick={closeFilePreview}
              >
                &times;
              </button>
            </div>
            <div className="w-full h-5/6">
              {filePreview.type === "pdf" && (
                <iframe src={filePreview.url} className="w-full h-full" title="PDF preview"></iframe>
              )}
              {filePreview.type === "image" && (
                <img src={filePreview.url} alt="File preview" className="max-w-full max-h-full mx-auto" />
              )}
              {filePreview.type === "other" && (
                <div className="text-center">
                  <p>This file type cannot be previewed.</p>
                  <a href={filePreview.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Download File
                  </a>
                </div>
              )}
            </div>
            </div>
            </div>
            )}
            </div>
            );
            };

            export default Donations;