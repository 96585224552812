import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet
} from "react-router-dom";
import { auth, db } from "./firebase";
import { User as FirebaseUser } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import SignIn from "./components/SignIn";
import SignUp from "./components/signup";
import ForgotPassword from "./components/forgotpassword";
import AdminDashboard from "./components/admindashboard";
import Members from "./components/members";
import Sponsors from "./components/sponsors";
import Invoices from "./components/invoices";
import Donations from "./components/donations";
import ErrorBoundary from "./components/ErrorBoundary";
import Home from "./components/Home";
import Profile from "./components/profile";
import Campanii from "./components/campanii";
import { Propuneri } from "./components/propuneri";
import PropPrimite from "./components/propprimite";
import Formular from "./components/formular";
import { Sondaje } from "./components/sondaje";
import SondajeUsers from "./components/sondajeusers";
import ChangePass from "./components/changepass";

const App: React.FC = () => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        setUserRole(userDoc.exists() ? userDoc.data()?.role || "user" : "user");
      } else {
        setUserRole(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const ProtectedRoute: React.FC<{ element: React.ReactElement, requiredRole?: string }> = ({ element, requiredRole }) => {
    if (!user) return <Navigate to="/signin" replace />;
    if (requiredRole && userRole !== requiredRole) return <Navigate to="/" replace />;
    return element;
  };

  return (
    <ErrorBoundary>
      <Router>
        <div className="app flex flex-col min-h-screen">
          <Routes>
            <Route 
              path="/" 
              element={
                user ? (
                  userRole === "admin" ? (
                    <Navigate to="/admindashboard" replace />
                  ) : (
                    <Home />
                  )
                ) : (
                  <Navigate to="/signin" replace />
                )
              }
            >
              <Route path="profile" element={<ProtectedRoute element={<Profile />} />} />
              <Route path="campanii" element={<ProtectedRoute element={<Campanii />} />} />
              <Route path="propuneri" element={<ProtectedRoute element={<Propuneri />} />} />
              <Route path="formular" element={<ProtectedRoute element={<Formular />} />} />
              <Route path="sondaje/*" element={<ProtectedRoute element={<SondajeUsers />} />} />
              <Route path="schimba-parola" element={<ProtectedRoute element={<ChangePass />} />} />
            </Route>
            <Route 
              path="/signin" 
              element={
                user ? (
                  userRole === "admin" ? (
                    <Navigate to="/admindashboard" replace />
                  ) : (
                    <Navigate to="/" replace />
                  )
                ) : (
                  <SignIn />
                )
              } 
            />
            <Route 
              path="/signup" 
              element={
                user ? (
                  userRole === "admin" ? (
                    <Navigate to="/admindashboard" replace />
                  ) : (
                    <Navigate to="/" replace />
                  )
                ) : (
                  <SignUp />
                )
              } 
            />
            <Route 
              path="/forgot-password" 
              element={
                user ? (
                  userRole === "admin" ? (
                    <Navigate to="/admindashboard" replace />
                  ) : (
                    <Navigate to="/" replace />
                  )
                ) : (
                  <ForgotPassword />
                )
              } 
            />
            <Route
              path="/admindashboard/*"
              element={<ProtectedRoute element={<AdminDashboard />} requiredRole="admin" />}
            />
            <Route
              path="/members"
              element={<ProtectedRoute element={<Members />} requiredRole="admin" />}
            />
            <Route
              path="/sponsors"
              element={<ProtectedRoute element={<Sponsors />} requiredRole="admin" />}
            />
            <Route
              path="/invoices"
              element={<ProtectedRoute element={<Invoices />} requiredRole="admin" />}
            />
            <Route
              path="/donations"
              element={<ProtectedRoute element={<Donations />} requiredRole="admin" />}
            />
            <Route
              path="/propuneri-primite"
              element={<ProtectedRoute element={<PropPrimite />} requiredRole="admin" />}
            />
            <Route
              path="/sondaje-admin"
              element={<ProtectedRoute element={<Sondaje />} requiredRole="admin" />}
            />
          </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;