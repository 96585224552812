import React, { useState, useEffect, useRef } from 'react';
import { User } from 'firebase/auth';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import SignatureCanvas from 'react-signature-canvas';

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface Invoice {
  id: string;
  date: string;
  amount: number;
  status: string;
}

interface FormData {
  Ap: string;
  Bloc: string;
  CNP: string;
  "Cod postal": string;
  Email: string;
  Etaj: string;
  Fax: string;
  "Initiala tatalui": string;
  Judet: string;
  Localitate: string;
  Numar: string;
  Nume: string;
  Prenume: string;
  Scara: string;
  Strada: string;
  Telefon: string;
  Semnatura?: string;
}

const initialFormData: FormData = {
  Ap: "",
  Bloc: "",
  CNP: "",
  "Cod postal": "",
  Email: "",
  Etaj: "",
  Fax: "",
  "Initiala tatalui": "",
  Judet: "",
  Localitate: "",
  Numar: "",
  Nume: "",
  Prenume: "",
  Scara: "",
  Strada: "",
  Telefon: "",
};

const Profile: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error'>('success');
  const [activeTab, setActiveTab] = useState('datecont');
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const signatureRef = useRef<SignatureCanvas>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        await fetchUserProfile(currentUser.uid);
        await fetchInvoices(currentUser.uid);
        await fetchFormData(currentUser.uid);
        await checkAdminStatus(currentUser.uid);
      }
    });
    return () => unsubscribeAuth();
  }, []);

  const fetchUserProfile = async (userId: string) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserProfile({
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          email: userData.email || '',
          phoneNumber: userData.phoneNumber || '',
        });
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      showAlert('Eroare la încărcarea profilului utilizatorului.', 'error');
    }
  };

  const fetchInvoices = async (userId: string) => {
    try {
      const invoicesQuery = query(collection(db, 'invoices'), where('userId', '==', userId));
      const invoicesSnapshot = await getDocs(invoicesQuery);
      const fetchedInvoices = invoicesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Invoice));
      setInvoices(fetchedInvoices);
    } catch (error) {
      console.error('Error fetching invoices:', error);
      setInvoices([]);
    }
  };

  const fetchFormData = async (userId: string) => {
    try {
      const formDocRef = doc(db, 'formular', userId);
      const formDoc = await getDoc(formDocRef);
      if (formDoc.exists()) {
        setFormData(formDoc.data() as FormData);
      }
    } catch (error) {
      console.error('Error fetching form data:', error);
      showAlert('Eroare la încărcarea datelor formularului.', 'error');
    }
  };

  const checkAdminStatus = async (userId: string) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.role === 'admin');
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
      setIsAdmin(false);
    }
  };

  const handleSaveProfile = async () => {
    try {
      const userDocRef = doc(db, 'users', user?.uid as string);
      await updateDoc(userDocRef, {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        phoneNumber: userProfile.phoneNumber,
      });
      setIsEditing(false);
      showAlert('Profilul a fost actualizat cu succes!', 'success');
    } catch (error) {
      console.error('Error updating user profile:', error);
      showAlert('Eroare la actualizarea profilului.', 'error');
    }
  };

  const handleFormDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveFormData = async () => {
    if (!user) return;
    try {
      const formDocRef = doc(db, 'formular', user.uid);
      await setDoc(formDocRef, formData, { merge: true });
      showAlert('Datele formularului au fost salvate cu succes!', 'success');
    } catch (error) {
      console.error('Error updating form data:', error);
      showAlert('Eroare la salvarea datelor formularului.', 'error');
    }
  };

  const handleSaveSignature = async () => {
    if (!user || !signatureRef.current) return;
    try {
      const signatureData = signatureRef.current.toDataURL();
      const formDocRef = doc(db, 'formular', user.uid);
      await setDoc(formDocRef, { Semnatura: signatureData }, { merge: true });
      setFormData(prevData => ({ ...prevData, Semnatura: signatureData }));
      showAlert('Semnătura a fost salvată cu succes!', 'success');
    } catch (error) {
      console.error('Error saving signature:', error);
      showAlert('Eroare la salvarea semnăturii.', 'error');
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      showAlert('Parolele noi nu corespund.', 'error');
      return;
    }

    try {
      // Implement password change logic here
      showAlert('Parola a fost schimbată cu succes!', 'success');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      showAlert('Eroare la schimbarea parolei. Vă rugăm să încercați din nou.', 'error');
    }
  };

  const showAlert = (message: string, type: 'success' | 'error') => {
    setAlertMessage(message);
    setAlertType(type);
    setIsAlertVisible(true);
    setTimeout(() => setIsAlertVisible(false), 5000);
  };

  return (
    <div className="profile p-4 bg-gray-100 min-h-screen">
      {isAlertVisible && (
        <div className={`p-4 mb-4 text-sm text-gray-900 rounded-lg ${
          alertType === 'success' ? 'bg-green-400' : 'bg-red-400'
        }`} role="alert">
          <span className="font-medium">{alertType === 'success' ? 'Succes!' : 'Eroare!'}</span> {alertMessage}
        </div>
      )}
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Profilul Tău {isAdmin ? '(Admin)' : ''}</h2>

      <div className="w-full max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
        <div className="bg-gray-200 p-1">
          <div className="flex">
            {['Date Cont', 'Facturare', 'Date Formular', 'Semnătură', 'Parolă'].map((tab) => (
              <button
                key={tab}
                className={`flex-1 px-4 py-2 text-sm font-medium rounded-md focus:outline-none transition-colors duration-200 ${
                  activeTab === tab.toLowerCase().replace(' ', '')
                    ? "bg-white text-blue-600 shadow"
                    : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                }`}
                onClick={() => setActiveTab(tab.toLowerCase().replace(' ', ''))}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        <div className="p-6">
          {activeTab === 'datecont' && (
            <div>
              <h3 className="text-xl font-bold mb-4 text-gray-800">Date Cont</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">Prenume</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={userProfile.firstName}
                    onChange={(e) => setUserProfile(prev => ({ ...prev, firstName: e.target.value }))}
                    disabled={!isEditing}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="lastName">Nume</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={userProfile.lastName}
                    onChange={(e) => setUserProfile(prev => ({ ...prev, lastName: e.target.value }))}
                    disabled={!isEditing}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={userProfile.email}
                    onChange={(e) => setUserProfile(prev => ({ ...prev, email: e.target.value }))}
                    disabled={!isEditing}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="phoneNumber">Număr de telefon</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={userProfile.phoneNumber}
                    onChange={(e) => setUserProfile(prev => ({ ...prev, phoneNumber: e.target.value }))}
                    disabled={!isEditing}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="mt-6">
                {isEditing ? (
                  <>
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={handleSaveProfile}
                    >
                      Salvează
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => setIsEditing(false)}
                    >
                      Anulează
                    </button>
                  </>
                ) : (
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => setIsEditing(true)}
                  >
                    Editează
                  </button>
                )}
              </div>
            </div>
          )}

          {activeTab === 'facturare' && (
            <div>
              <h3 className="text-xl font-bold mb-4 text-gray-800">Facturare</h3>
              {invoices.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suma</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stare</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {invoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <td className="px-6 py-4 whitespace-nowrap">{invoice.date}</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{invoice.amount} RON</td>
                                                <td className="px-6 py-4 whitespace-nowrap">{invoice.status}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      ) : (
                                        <p className="text-gray-600">Nu există facturi.</p>
                                      )}
                                    </div>
                                  )}

                                  {activeTab === 'dateformular' && (
                                    <div>
                                      <h3 className="text-xl font-bold mb-4 text-gray-800">Date Formular</h3>
                                      <form onSubmit={(e) => { e.preventDefault(); handleSaveFormData(); }} className="space-y-4">
                                        <div>
                                          <label htmlFor="Ap" className="block text-sm font-medium text-gray-700">Ap</label>
                                          <input
                                            type="text"
                                            id="Ap"
                                            name="Ap"
                                            value={formData.Ap}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Bloc" className="block text-sm font-medium text-gray-700">Bloc</label>
                                          <input
                                            type="text"
                                            id="Bloc"
                                            name="Bloc"
                                            value={formData.Bloc}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="CNP" className="block text-sm font-medium text-gray-700">CNP</label>
                                          <input
                                            type="text"
                                            id="CNP"
                                            name="CNP"
                                            value={formData.CNP}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Cod postal" className="block text-sm font-medium text-gray-700">Cod postal</label>
                                          <input
                                            type="text"
                                            id="Cod postal"
                                            name="Cod postal"
                                            value={formData["Cod postal"]}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Email" className="block text-sm font-medium text-gray-700">Email</label>
                                          <input
                                            type="email"
                                            id="Email"
                                            name="Email"
                                            value={formData.Email}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Etaj" className="block text-sm font-medium text-gray-700">Etaj</label>
                                          <input
                                            type="text"
                                            id="Etaj"
                                            name="Etaj"
                                            value={formData.Etaj}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Fax" className="block text-sm font-medium text-gray-700">Fax</label>
                                          <input
                                            type="text"
                                            id="Fax"
                                            name="Fax"
                                            value={formData.Fax}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Initiala tatalui" className="block text-sm font-medium text-gray-700">Inițiala tatălui</label>
                                          <input
                                            type="text"
                                            id="Initiala tatalui"
                                            name="Initiala tatalui"
                                            value={formData["Initiala tatalui"]}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Judet" className="block text-sm font-medium text-gray-700">Județ</label>
                                          <input
                                            type="text"
                                            id="Judet"
                                            name="Judet"
                                            value={formData.Judet}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Localitate" className="block text-sm font-medium text-gray-700">Localitate</label>
                                          <input
                                            type="text"
                                            id="Localitate"
                                            name="Localitate"
                                            value={formData.Localitate}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Numar" className="block text-sm font-medium text-gray-700">Număr</label>
                                          <input
                                            type="text"
                                            id="Numar"
                                            name="Numar"
                                            value={formData.Numar}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Nume" className="block text-sm font-medium text-gray-700">Nume</label>
                                          <input
                                            type="text"
                                            id="Nume"
                                            name="Nume"
                                            value={formData.Nume}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Prenume" className="block text-sm font-medium text-gray-700">Prenume</label>
                                          <input
                                            type="text"
                                            id="Prenume"
                                            name="Prenume"
                                            value={formData.Prenume}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Scara" className="block text-sm font-medium text-gray-700">Scara</label>
                                          <input
                                            type="text"
                                            id="Scara"
                                            name="Scara"
                                            value={formData.Scara}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Strada" className="block text-sm font-medium text-gray-700">Strada</label>
                                          <input
                                            type="text"
                                            id="Strada"
                                            name="Strada"
                                            value={formData.Strada}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="Telefon" className="block text-sm font-medium text-gray-700">Telefon</label>
                                          <input
                                            type="text"
                                            id="Telefon"
                                            name="Telefon"
                                            value={formData.Telefon}
                                            onChange={handleFormDataChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div className="flex justify-end">
                                          <button
                                            type="submit"
                                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                          >
                                            Salvează Date Formular
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  )}

                                  {activeTab === 'semnătură' && (
                                    <div>
                                      <h3 className="text-xl font-bold mb-4 text-gray-800">Semnătură {isAdmin ? '(Admin)' : ''}</h3>
                                      <div className="border border-gray-300 rounded-md mb-4">
                                        <SignatureCanvas
                                          ref={signatureRef}
                                          canvasProps={{
                                            width: 500,
                                            height: 200,
                                            className: 'signature-canvas'
                                          }}
                                        />
                                      </div>
                                      {formData.Semnatura && (
                                        <div className="mt-4">
                                          <h4 className="text-lg font-semibold mb-2">Semnătură Salvată:</h4>
                                          <img src={formData.Semnatura} alt="Semnătură Salvată" className="border border-gray-300 rounded-md" />
                                        </div>
                                      )}
                                      <div className="flex justify-between mt-4">
                                        <button
                                          onClick={() => signatureRef.current?.clear()}
                                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                        >
                                          Șterge Semnătura
                                        </button>
                                        <button
                                          onClick={handleSaveSignature}
                                          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                        >
                                          Salvează Semnătura
                                        </button>
                                      </div>
                                    </div>
                                  )}

                                  {activeTab === 'parolă' && (
                                    <div>
                                      <h3 className="text-xl font-bold mb-4 text-gray-800">Schimbă Parola</h3>
                                      <form onSubmit={(e) => { e.preventDefault(); handleChangePassword(); }} className="space-y-4">
                                        <div>
                                          <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                                            Parola curentă
                                          </label>
                                          <input
                                            type="password"
                                            id="currentPassword"
                                            value={currentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                                            Parola nouă
                                          </label>
                                          <input
                                            type="password"
                                            id="newPassword"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div>
                                          <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                            Confirmă parola nouă
                                          </label>
                                          <input
                                            type="password"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                          />
                                        </div>
                                        <div className="flex items-center justify-between">
                                          <button
                                            type="submit"
                                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                          >
                                            Schimbă Parola
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        };

                        export default Profile;