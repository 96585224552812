import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  Timestamp,
  updateDoc,
  orderBy,
  limit,
  startAfter,
  Query,
  DocumentData,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../firebase";
import { ChevronLeft, ChevronRight, ArrowUp, ArrowDown, Edit, Trash2 } from "lucide-react";

interface Invoice {
  id: string;
  amount: number;
  date: Timestamp;
  name: string;
  file?: string;
  fileName?: string;
}

const ITEMS_PER_PAGE = 20;

const formatDateForInput = (date: Date | null): string => {
  return date ? date.toISOString().split('T')[0] : '';
};

const DatePicker: React.FC<{
  selected: Date | null;
  onChange: (date: Date | null) => void;
  placeholder: string;
  isDarkTheme: boolean;
}> = ({ selected, onChange, placeholder, isDarkTheme }) => {
  const [inputValue, setInputValue] = useState(formatDateForInput(selected));

  useEffect(() => {
    setInputValue(formatDateForInput(selected));
  }, [selected]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    const date = new Date(newValue);
    if (!isNaN(date.getTime())) {
      onChange(date);
    } else {
      onChange(null);
    }
  };

  return (
    <div className="relative">
      <input
        type="date"
        className={`border text-sm rounded-lg block w-full ps-10 p-2.5 ${
          isDarkTheme
            ? 'bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500'
            : 'bg-white border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500'
        }`}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
        <svg className={`w-4 h-4 ${isDarkTheme ? 'text-gray-400' : 'text-gray-600'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
        </svg>
      </div>
    </div>
  );
};

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [newInvoice, setNewInvoice] = useState<Omit<Invoice, "id" | "date">>({
    amount: 0,
    name: "",
  });
  const [newInvoiceDate, setNewInvoiceDate] = useState<Date | null>(new Date());
  const [editingInvoice, setEditingInvoice] = useState<Invoice | null>(null);
  const [editingDate, setEditingDate] = useState<Date | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ isOpen: boolean; invoiceId: string | null }>({
    isOpen: false,
    invoiceId: null,
  });
  const [filePreview, setFilePreview] = useState<{ isOpen: boolean; url: string; type: string }>({
    isOpen: false,
    url: "",
    type: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<"date" | "name" | "amount">("date");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [totalInvoices, setTotalInvoices] = useState(0);

  useEffect(() => {
    fetchInvoices();
    fetchTotalInvoices();
  }, [sortField, sortDirection, currentPage, searchTerm]);

  const fetchTotalInvoices = async () => {
    try {
      const snapshot = await getDocs(collection(db, "invoices"));
      setTotalInvoices(snapshot.size);
    } catch (err) {
      console.error("Error fetching total invoices:", err);
    }
  };

  const fetchInvoices = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let invoicesQuery: Query<DocumentData> = collection(db, "invoices");

      invoicesQuery = query(invoicesQuery, orderBy(sortField, sortDirection));

      if (currentPage > 1 && lastVisible) {
        invoicesQuery = query(invoicesQuery, startAfter(lastVisible));
      }

      invoicesQuery = query(invoicesQuery, limit(ITEMS_PER_PAGE));

      const invoicesSnapshot = await getDocs(invoicesQuery);

      if (!invoicesSnapshot.empty) {
        setLastVisible(invoicesSnapshot.docs[invoicesSnapshot.docs.length - 1]);
      }

      let invoicesList = invoicesSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          amount: Number(data.amount),
          date: data.date instanceof Timestamp ? data.date : Timestamp.now(),
          name: data.name,
          file: data.file,
          fileName: data.fileName,
        } as Invoice;
      });

      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        invoicesList = invoicesList.filter(invoice => 
          invoice.name.toLowerCase().includes(lowerSearchTerm)
        );
      }

      setInvoices(invoicesList);
    } catch (err) {
      console.error("Error fetching invoices:", err);
      setError("Failed to fetch invoices. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addInvoice = async () => {
    if (!newInvoiceDate) {
      setError("Please select a valid date.");
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      let fileUrl = "";
      let fileName = "";
      if (file) {
        const storageRef = ref(storage, `invoice_files/${file.name}`);
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
        fileName = file.name;
      }

      const invoiceWithDate = {
        ...newInvoice,
        amount: Number(newInvoice.amount),
        date: Timestamp.fromDate(newInvoiceDate),
        file: fileUrl,
        fileName,
      };
      const docRef = await addDoc(collection(db, "invoices"), invoiceWithDate);
      const newInvoiceWithId = { ...invoiceWithDate, id: docRef.id };
      setInvoices([...invoices, newInvoiceWithId]);
      setNewInvoice({
        amount: 0,
        name: "",
      });
      setNewInvoiceDate(new Date());
      setFile(null);
      setIsAddingNew(false);
      setTotalInvoices(prev => prev + 1);
    } catch (err) {
      console.error("Error adding new invoice:", err);
      setError("Failed to add new invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateInvoice = async () => {
    if (!editingInvoice || !editingDate) {
      setError("Invalid invoice data. Please try again.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      let fileUrl = editingInvoice.file;
      let fileName = editingInvoice.fileName;

      if (file) {
        if (editingInvoice.file) {
          const oldFileRef = ref(storage, editingInvoice.file);
          await deleteObject(oldFileRef);
        }

        const storageRef = ref(storage, `invoice_files/${file.name}`);
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
        fileName = file.name;
      }

      const updatedInvoice = {
        ...editingInvoice,
        amount: Number(editingInvoice.amount),
        date: Timestamp.fromDate(editingDate),
        file: fileUrl,
        fileName,
      };

      await updateDoc(doc(db, "invoices", editingInvoice.id), updatedInvoice);

      setInvoices(invoices.map(i => i.id === editingInvoice.id ? updatedInvoice : i));
      setEditingInvoice(null);
      setEditingDate(null);
      setFile(null);
    } catch (err) {
      console.error("Error updating invoice:", err);
      setError("Failed to update invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = (id: string) => {
    setDeleteConfirmation({ isOpen: true, invoiceId: id });
  };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, invoiceId: null });
  };

  const deleteInvoice = async () => {
    if (!deleteConfirmation.invoiceId) return;

    setIsLoading(true);
    setError(null);
    try {
      const invoiceToDelete = invoices.find(i => i.id === deleteConfirmation.invoiceId);
      if (invoiceToDelete && invoiceToDelete.file) {
        const fileRef = ref(storage, invoiceToDelete.file);
        await deleteObject(fileRef);
      }
      await deleteDoc(doc(db, "invoices", deleteConfirmation.invoiceId));
      setInvoices(invoices.filter((invoice) => invoice.id !== deleteConfirmation.invoiceId));
      setDeleteConfirmation({ isOpen: false, invoiceId: null });
      setTotalInvoices(prev => prev - 1);
    } catch (err) {
      console.error("Error deleting invoice:", err);
      setError("Failed to delete invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const openFilePreview = (url: string, fileName: string) => {
    const fileType = fileName.split('.').pop()?.toLowerCase();
    let type = "other";
    if (fileType === "pdf") {
      type = "pdf";
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileType || "")) {
      type = "image";
    }
    setFilePreview({ isOpen: true, url, type });
  };

  const closeFilePreview = () => {
    setFilePreview({ isOpen: false, url: "", type: "" });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handleSort = (field: "date" | "name" | "amount") => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection(field === "date" ? "desc" : "asc");
    }
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setLastVisible(null);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleEdit = (invoice: Invoice) => {
    setEditingInvoice(invoice);
    setEditingDate(invoice.date.toDate());
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + invoices.length - 1, totalInvoices);

  return (
    <div className="p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Invoices</h1>
      <div className="mb-4">
        <button
          className="bg-[#93C572] hover:bg-[#7ba35e] text-white px-4 py-2 rounded transition duration-300 ease-in-out"
          onClick={() => setIsAddingNew(true)}
          disabled={isLoading}
        >
          Add New Invoice
        </button>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4 flex justify-between items-center">
        <div className="flex-1 mr-4">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
            className="p-2 border border-border rounded w-full bg-input text-text"
          />
        </div>
      </div>
      {isAddingNew && (
        <div className="mb-4 p-4 border border-border rounded bg-form">
          <input
            type="number"
            placeholder="Amount"
            value={newInvoice.amount}
            onChange={(e) =>
              setNewInvoice({ ...newInvoice, amount: parseFloat(e.target.value) || 0 })
                                                                            }
                                                                            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
                                                                          />
                                                                          <input
                                                                            type="text"
                                                                            placeholder="Name"
                                                                            value={newInvoice.name}
                                                                            onChange={(e) =>
                                                                              setNewInvoice({ ...newInvoice, name: e.target.value })
                                                                            }
                                                                            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
                                                                          />
                                                                          <DatePicker
                                                                            selected={newInvoiceDate}
                                                                            onChange={(date: Date | null) => setNewInvoiceDate(date)}
                                                                            placeholder="Select date"
                                                                            isDarkTheme={isDarkTheme}
                                                                          />
                                                                          <input
                                                                            type="file"
                                                                            onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                                                                            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
                                                                          />
                                                                          <button
                                                                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
                                                                            onClick={addInvoice}
                                                                            disabled={isLoading}
                                                                          >
                                                                            {isLoading ? "Saving..." : "Save"}
                                                                          </button>
                                                                          <button
                                                                            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                                                                            onClick={() => setIsAddingNew(false)}
                                                                            disabled={isLoading}
                                                                          >
                                                                            Cancel
                                                                          </button>
                                                                        </div>
                                                                      )}
                                                                      <div className="overflow-x-auto">
                                                                        <table className="w-full border-collapse">
                                                                          <thead>
                                                                            <tr className="bg-table-header">
                                                                              <th className="border border-border p-2 text-left">Nr.</th>
                                                                              <th className="border border-border p-2 text-left">Amount</th>
                                                                              <th 
                                                                                className="border border-border p-2 text-left cursor-pointer"
                                                                                onClick={() => handleSort("date")}
                                                                              >
                                                                                Date
                                                                                {sortField === "date" && (
                                                                                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                                                                                )}
                                                                              </th>
                                                                              <th 
                                                                                className="border border-border p-2 text-left cursor-pointer"
                                                                                onClick={() => handleSort("name")}
                                                                              >
                                                                                Name
                                                                                {sortField === "name" && (
                                                                                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                                                                                )}
                                                                              </th>
                                                                              <th className="border border-border p-2 text-left">File</th>
                                                                              <th className="border border-border p-2 text-left">Actions</th>
                                                                            </tr>
                                                                          </thead>
                                                                          <tbody>
                                                                            {invoices.map((invoice, index) => (
                                                                              <tr key={invoice.id} className="bg-table-row hover:bg-table-row-hover">
                                                                                <td className="border border-border p-2">{startIndex + index}</td>
                                                                                <td className="border border-border p-2">
                                                                                  {editingInvoice?.id === invoice.id ? (
                                                                                    <input
                                                                                      type="number"
                                                                                      value={editingInvoice.amount}
                                                                                      onChange={(e) => setEditingInvoice({ ...editingInvoice, amount: parseFloat(e.target.value) || 0 })}
                                                                                      className="w-full p-1 border rounded bg-input text-text"
                                                                                    />
                                                                                  ) : (
                                                                                    `RON ${Number(invoice.amount).toFixed(2)}`
                                                                                  )}
                                                                                </td>
                                                                                <td className="border border-border p-2">
                                                                                  {editingInvoice?.id === invoice.id ? (
                                                                                    <DatePicker
                                                                                      selected={editingDate}
                                                                                      onChange={(date: Date | null) => setEditingDate(date)}
                                                                                      placeholder="Select date"
                                                                                      isDarkTheme={isDarkTheme}
                                                                                    />
                                                                                  ) : (
                                                                                    formatDate(invoice.date.toDate())
                                                                                  )}
                                                                                </td>
                                                                                <td className="border border-border p-2">
                                                                                  {editingInvoice?.id === invoice.id ? (
                                                                                    <input
                                                                                      type="text"
                                                                                      value={editingInvoice.name}
                                                                                      onChange={(e) => setEditingInvoice({ ...editingInvoice, name: e.target.value })}
                                                                                      className="w-full p-1 border rounded bg-input text-text"
                                                                                    />
                                                                                  ) : (
                                                                                    invoice.name
                                                                                  )}
                                                                                </td>
                                                                                <td className="border border-border p-2">
                                                                                  {editingInvoice?.id === invoice.id ? (
                                                                                    <input
                                                                                      type="file"
                                                                                      onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                                                                                      className="w-full p-1 border rounded bg-input text-text"
                                                                                    />
                                                                                  ) : invoice.file ? (
                                                                                    <button
                                                                                      onClick={() => openFilePreview(invoice.file!, invoice.fileName!)}
                                                                                      className="text-blue-500 hover:underline"
                                                                                    >
                                                                                      View File
                                                                                    </button>
                                                                                  ) : (
                                                                                    "No file"
                                                                                  )}
                                                                                </td>
                                                                                <td className="border border-border p-2">
                                                                                  {editingInvoice?.id === invoice.id ? (
                                                                                    <>
                                                                                      <button
                                                                                        className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded mr-2 transition duration-300 ease-in-out"
                                                                                        onClick={updateInvoice}
                                                                                        disabled={isLoading}
                                                                                      >
                                                                                        Save
                                                                                      </button>
                                                                                      <button
                                                                                        className="bg-gray-500 hover:bg-gray-600 text-white px-2 py-1 rounded transition duration-300 ease-in-out"
                                                                                        onClick={() => setEditingInvoice(null)}
                                                                                        disabled={isLoading}
                                                                                      >
                                                                                        Cancel
                                                                                      </button>
                                                                                    </>
                                                                                  ) : (
                                                                                    <div className="flex space-x-2">
                                                                                      <button
                                                                                        className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                                                                        onClick={() => handleEdit(invoice)}
                                                                                        disabled={isLoading}
                                                                                      >
                                                                                        <Edit className="w-4 h-4 mr-1" /> Edit
                                                                                      </button>
                                                                                      <button
                                                                                        className="bg-[#DC3545] hover:bg-[#c82333] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                                                                        onClick={() => confirmDelete(invoice.id)}
                                                                                        disabled={isLoading}
                                                                                      >
                                                                                        <Trash2 className="w-4 h-4 mr-1" /> Delete
                                                                                      </button>
                                                                                    </div>
                                                                                  )}
                                                                                </td>
                                                                              </tr>
                                                                            ))}
                                                                          </tbody>
                                                                        </table>
                                                                      </div>
                                                                      <div className="mt-8 flex justify-between items-center">
                                                                        <span className="text-sm">
                                                                          Showing {startIndex}-{endIndex} of {totalInvoices}
                                                                        </span>
                                                                        <div className="flex-1 flex justify-center items-center space-x-2">
                                                                          <button
                                                                            className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                                                            onClick={handlePrevPage}
                                                                            disabled={currentPage === 1 || isLoading}
                                                                          >
                                                                            <ChevronLeft className="h-5 w-5" />
                                                                          </button>
                                                                          <span className="text-sm">
                                                                            {currentPage} of {Math.ceil(totalInvoices / ITEMS_PER_PAGE)}
                                                                          </span>
                                                                          <button
                                                                            className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                                                            onClick={handleNextPage}
                                                                            disabled={endIndex >= totalInvoices || isLoading}
                                                                          >
                                                                            <ChevronRight className="h-5 w-5" />
                                                                          </button>
                                                                        </div>
                                                                        <div className="w-[150px]"></div> {/* This empty div balances the layout */}
                                                                      </div>

                                                                      {deleteConfirmation.isOpen && (
                                                                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                                                          <div className="bg-form p-6 rounded-lg text-text">
                                                                            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                                                                            <p className="mb-4">Are you sure you want to delete this invoice?</p>
                                                                            <div className="flex justify-end">
                                                                              <button
                                                                                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
                                                                                onClick={cancelDelete}
                                                                              >
                                                                                Cancel
                                                                              </button>
                                                                              <button
                                                                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                                                                                onClick={deleteInvoice}
                                                                              >
                                                                                Delete
                                                                              </button>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}

                                                                      {filePreview.isOpen && (
                                                                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                                                          <div className="bg-form p-6 rounded-lg w-3/4 h-3/4 text-text">
                                                                            <div className="flex justify-between items-center mb-4">
                                                                              <h2 className="text-xl font-bold">File Preview</h2>
                                                                              <button
                                                                                className="text-2xl"
                                                                                onClick={closeFilePreview}
                                                                              >
                                                                                &times;
                                                                              </button>
                                                                            </div>
                                                                            <div className="w-full h-5/6">
                                                                              {filePreview.type === "pdf" && (
                                                                                <iframe src={filePreview.url} className="w-full h-full" title="PDF preview"></iframe>
                                                                              )}
                                                                              {filePreview.type === "image" && (
                                                                                <img src={filePreview.url} alt="File preview" className="max-w-full max-h-full mx-auto" />
                                                                              )}
                                                                              {filePreview.type === "other" && (
                                                                                <div className="text-center">
                                                                                  <p>This file type cannot be previewed.</p>
                                                                                  <a href={filePreview.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                                                                    Download File
                                                                                  </a>
                                                                                </div>
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  );
                                                                };

                                                                export default Invoices;