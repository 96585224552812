import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// Replace with your actual Stripe publishable key
const stripePromise = loadStripe("your_stripe_publishable_key");

const SignUpForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const handleNextStep = (e: React.FormEvent) => {
    e.preventDefault();
    setStep(2);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("An error occurred with the payment form. Please try again.");
      return;
    }

    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (stripeError) {
      setError(stripeError.message || "An error occurred with the payment. Please try again.");
      return;
    }

    try {
      // Here you would typically send the paymentMethod.id to your server
      // to create a subscription with Stripe's API
      console.log('PaymentMethod:', paymentMethod);

      // For this example, we'll assume the payment was successful
      // Now create the user account
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Set a display name
      const displayName = `${firstName} ${lastName}`.trim();
      await updateProfile(user, {
        displayName: displayName || email.split("@")[0],
      });

      // Create a user document in Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        displayName: user.displayName,
        firstName,
        lastName,
        role: "User",
        subscriptionStatus: "active",
        subscriptionType: "Cotizație lunară",
      });

      // Navigate to the home page
      navigate("/");
    } catch (error: any) {
      if (error.code) {
        switch (error.code) {
          case "auth/email-already-in-use":
            setError("An account with this email already exists");
            break;
          case "auth/invalid-email":
            setError("Invalid email address");
            break;
          case "auth/weak-password":
            setError("Password is too weak. It should be at least 6 characters long");
            break;
          default:
            setError(`Failed to create an account: ${error.message}`);
        }
      } else {
        setError(`Failed to create an account: ${error.message || "Unknown error"}`);
      }
      console.error("Signup error:", error);
    }
  };

  return (
    <div className="max-w-md w-full space-y-8">
      <div>
        <img
          className="mx-auto h-48 w-auto"
          src="/LogoFreeWay.png"
          alt="Asociatia Free Way"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {step === 1 ? "Create your account" : "Cotizație lunară"}
        </h2>
      </div>
      {step === 1 ? (
        <form className="mt-8 space-y-6" onSubmit={handleNextStep}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Prenume
              </label>
              <input
                id="first-name"
                name="firstName"
                type="text"
                required
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                Nume
              </label>
              <input
                id="last-name"
                name="lastName"
                type="text"
                required
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Parola
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Next
            </button>
          </div>
        </form>
      ) : (
        <div className="mt-8 space-y-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Cotizație</h3>
            <p className="text-gray-700 mb-4">Vă abonați la:</p>
            <div className="flex justify-between items-center mb-4">
              <span className="text-lg font-bold text-green-600">Cotizație lunară</span>
              <span className="text-2xl font-bold text-green-600">15 RON / Lună</span>
            </div>
            <p className="text-sm text-gray-500">
              Acesta este un abonament lunar recurent. Veți fi taxat cu 15 RON în fiecare lună până la anulare.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm space-y-4">
              <div>
                <label htmlFor="card-element" className="block text-sm font-medium text-gray-700">
                  Credit or debit card
                </label>
                <div className="mt-1">
                  <CardElement
                    id="card-element"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                disabled={!stripe}
              >
                Înscriere
              </button>
            </div>
          </form>
        </div>
      )}
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      <div className="text-center">
        <Link
          to="/"
          className="font-medium text-green-600 hover:text-green-500"
        >
          Aveți deja un cont? Conectați-vă
        </Link>
      </div>
    </div>
  );
};

const SignUp: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Elements stripe={stripePromise}>
        <SignUpForm />
      </Elements>
    </div>
  );
};

export default SignUp;