import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
  limit,
  startAfter,
  Query,
  DocumentData,
} from "firebase/firestore";
import { db } from "../firebase";
import { ChevronLeft, ChevronRight, ArrowUp, ArrowDown, Edit, Trash2 } from "lucide-react";

interface Sponsor {
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  status: "Active" | "Potential" | "Inactive";
}

const ITEMS_PER_PAGE = 20;

const Sponsors: React.FC = () => {
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [newSponsor, setNewSponsor] = useState<Omit<Sponsor, "id">>({
    name: "",
    email: "",
    phonenumber: "",
    status: "Potential",
  });
  const [editingSponsor, setEditingSponsor] = useState<Sponsor | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ isOpen: boolean; sponsorId: string | null }>({
    isOpen: false,
    sponsorId: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<"name" | "status">("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [totalSponsors, setTotalSponsors] = useState(0);

  useEffect(() => {
    fetchSponsors();
    fetchTotalSponsors();
  }, [sortField, sortDirection, currentPage, searchTerm]);

  const fetchTotalSponsors = async () => {
    try {
      const snapshot = await getDocs(collection(db, "sponsors"));
      setTotalSponsors(snapshot.size);
    } catch (err) {
      console.error("Error fetching total sponsors:", err);
    }
  };

  const fetchSponsors = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let sponsorsQuery: Query<DocumentData> = collection(db, "sponsors");

      sponsorsQuery = query(sponsorsQuery, orderBy(sortField, sortDirection));

      if (currentPage > 1 && lastVisible) {
        sponsorsQuery = query(sponsorsQuery, startAfter(lastVisible));
      }

      sponsorsQuery = query(sponsorsQuery, limit(ITEMS_PER_PAGE));

      const sponsorsSnapshot = await getDocs(sponsorsQuery);

      if (!sponsorsSnapshot.empty) {
        setLastVisible(sponsorsSnapshot.docs[sponsorsSnapshot.docs.length - 1]);
      }

      let sponsorsList = sponsorsSnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() }) as Sponsor
      );

      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        sponsorsList = sponsorsList.filter(sponsor => 
          sponsor.name.toLowerCase().includes(lowerSearchTerm) ||
          sponsor.email.toLowerCase().includes(lowerSearchTerm) ||
          sponsor.phonenumber.toLowerCase().includes(lowerSearchTerm)
        );
      }

      setSponsors(sponsorsList);
    } catch (err) {
      console.error("Error fetching sponsors:", err);
      setError("Failed to fetch sponsors. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addSponsor = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const docRef = await addDoc(collection(db, "sponsors"), newSponsor);
      const newSponsorWithId = { ...newSponsor, id: docRef.id };
      setSponsors([...sponsors, newSponsorWithId]);
      setNewSponsor({
        name: "",
        email: "",
        phonenumber: "",
        status: "Potential",
      });
      setIsAddingNew(false);
      setTotalSponsors(prev => prev + 1);
    } catch (err) {
      console.error("Error adding new sponsor:", err);
      setError("Failed to add new sponsor. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateSponsor = async () => {
    if (!editingSponsor) return;

    setIsLoading(true);
    setError(null);
    try {
      const { id, ...updatedData } = editingSponsor;
      await updateDoc(doc(db, "sponsors", id), updatedData);
      setSponsors(
        sponsors.map((sponsor) =>
          sponsor.id === id ? editingSponsor : sponsor
        )
      );
      setEditingSponsor(null);
    } catch (err) {
      console.error("Error updating sponsor:", err);
      setError("Failed to update sponsor. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateSponsorStatus = async (id: string, status: Sponsor["status"]) => {
    setIsLoading(true);
    setError(null);
    try {
      await updateDoc(doc(db, "sponsors", id), { status });
      setSponsors(
        sponsors.map((sponsor) =>
          sponsor.id === id ? { ...sponsor, status } : sponsor
        )
      );
    } catch (err) {
      console.error("Error updating sponsor status:", err);
      setError("Failed to update sponsor status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = (id: string) => {
    setDeleteConfirmation({ isOpen: true, sponsorId: id });
  };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, sponsorId: null });
  };

  const deleteSponsor = async () => {
    if (!deleteConfirmation.sponsorId) return;

    setIsLoading(true);
    setError(null);
    try {
      await deleteDoc(doc(db, "sponsors", deleteConfirmation.sponsorId));
      setSponsors(sponsors.filter((sponsor) => sponsor.id !== deleteConfirmation.sponsorId));
      setDeleteConfirmation({ isOpen: false, sponsorId: null });
      setTotalSponsors(prev => prev - 1);
    } catch (err) {
      console.error("Error deleting sponsor:", err);
      setError("Failed to delete sponsor. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handleSort = (field: "name" | "status") => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setLastVisible(null);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + sponsors.length - 1, totalSponsors);

  return (
    <div className="p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Sponsors</h1>
      <div className="mb-4">
        <button
          className="bg-[#93C572] hover:bg-[#7ba35e] text-white px-4 py-2 rounded transition duration-300 ease-in-out"
          onClick={() => setIsAddingNew(true)}
          disabled={isLoading}
        >
          Add New Sponsor
        </button>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4 flex justify-between items-center">
        <div className="flex-1 mr-4">
          <input
            type="text"
            placeholder="Search by name, email, or phone"
            value={searchTerm}
            onChange={handleSearch}
            className="p-2 border border-border rounded w-full bg-input text-text"
          />
        </div>
      </div>
      {isAddingNew && (
        <div className="mb-4 p-4 border border-border rounded bg-form">
          <input
            type="text"
            placeholder="Name"
            value={newSponsor.name}
            onChange={(e) => setNewSponsor({ ...newSponsor, name: e.target.value })}
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <input
            type="email"
            placeholder="Email"
            value={newSponsor.email}
            onChange={(e) => setNewSponsor({ ...newSponsor, email: e.target.value })}
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <input
            type="tel"
            placeholder="Phone Number"
            value={newSponsor.phonenumber}
            onChange={(e) => setNewSponsor({ ...newSponsor, phonenumber: e.target.value })}
            className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
          />
          <select
            value={newSponsor.status}
            onChange={(e) => setNewSponsor({ ...newSponsor, status: e.target.value as Sponsor["status"] })}
            className="mb-2 p-2 border border-border rounded w-full bg-dropdown text-dropdown-text dark:bg-[#b1b2bc] dark:[&>option]:bg-[#b1b2bc]"
          >
            <option value="Potential">Potential</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
            onClick={addSponsor}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
            onClick={() => setIsAddingNew(false)}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-table-header">
              <th className="border border-border p-2 text-left">Nr.</th>
              <th 
                className="border border-border p-2 text-left cursor-pointer"
                onClick={() => handleSort("name")}
              >
                Name
                {sortField === "name" && (
                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                )}
              </th>
              <th className="border border-border p-2 text-left">Email</th>
              <th className="border border-border p-2 text-left">Phone Number</th>
              <th 
                className="border border-border p-2 text-left cursor-pointer"
                onClick={() => handleSort("status")}
              >
                Status
                {sortField === "status" && (
                  sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                )}
              </th>
              <th className="border border-border p-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {sponsors.map((sponsor, index) => (
              <tr key={sponsor.id} className="bg-table-row hover:bg-table-row-hover">
                <td className="border border-border p-2">{startIndex + index}</td>
                <td className="border border-border p-2">
                  {editingSponsor?.id === sponsor.id ? (
                    <input
                      type="text"
                      value={editingSponsor.name}
                      onChange={(e) => setEditingSponsor({ ...editingSponsor, name: e.target.value })}
                      className="w-full p-1 border rounded bg-input text-text"
                    />
                  ) : (
                    sponsor.name
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingSponsor?.id === sponsor.id ? (
                    <input
                      type="email"
                      value={editingSponsor.email}
                      onChange={(e) => setEditingSponsor({ ...editingSponsor, email: e.target.value })}
                      className="w-full p-1 border rounded bg-input text-text"
                    />
                  ) : (
                    sponsor.email
                  )}
                </td>
                <td className="border border-border p-2">
                  {editingSponsor?.id === sponsor.id ? (
                    <input
                      type="tel"
                      value={editingSponsor.phonenumber}
                      onChange={(e) => setEditingSponsor({ ...editingSponsor, phonenumber: e.target.value })}
                      className="w-full p-1 border rounded bg-input text-text"
                    />
                  ) : (
                    sponsor.phonenumber
                  )}
                </td>
                <td className="border border-border p-2">
                  <select
                    value={editingSponsor?.id === sponsor.id ? editingSponsor.status : sponsor.status}
                    onChange={(e) =>
                      editingSponsor?.id === sponsor.id
                        ? setEditingSponsor({ ...editingSponsor, status: e.target.value as Sponsor["status"] })
                        : updateSponsorStatus(sponsor.id, e.target.value as Sponsor["status"])
                    }
                    className="w-full p-1 border rounded bg-dropdown text-dropdown-text dark:bg-[#b1b2bc] dark:[&>option]:bg-[#b1b2bc]"
                    disabled={isLoading}
                  >
                    <option value="Potential">Potential</option>
                    <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                      </select>
                                    </td>
                                    <td className="border border-border p-2">
                                      {editingSponsor?.id === sponsor.id ? (
                                        <>
                                          <button
                                            className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded mr-2 transition duration-300 ease-in-out"
                                            onClick={updateSponsor}
                                            disabled={isLoading}
                                          >
                                            Save
                                          </button>
                                          <button
                                            className="bg-gray-500 hover:bg-gray-600 text-white px-2 py-1 rounded transition duration-300 ease-in-out"
                                            onClick={() => setEditingSponsor(null)}
                                            disabled={isLoading}
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      ) : (
                                        <div className="flex space-x-2">
                                          <button
                                            className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                            onClick={() => setEditingSponsor(sponsor)}
                                            disabled={isLoading}
                                          >
                                            <Edit className="w-4 h-4 mr-1" /> Edit
                                          </button>
                                          <button
                                            className="bg-[#DC3545] hover:bg-[#c82333] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                            onClick={() => confirmDelete(sponsor.id)}
                                            disabled={isLoading}
                                          >
                                            <Trash2 className="w-4 h-4 mr-1" /> Delete
                                          </button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="mt-8 flex justify-between items-center">
                            <span className="text-sm">
                              Showing {startIndex}-{endIndex} of {totalSponsors}
                            </span>
                            <div className="flex-1 flex justify-center items-center space-x-2">
                              <button
                                className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                onClick={handlePrevPage}
                                disabled={currentPage === 1 || isLoading}
                              >
                                <ChevronLeft className="h-5 w-5" />
                              </button>
                              <span className="text-sm">
                                {currentPage} of {Math.ceil(totalSponsors / ITEMS_PER_PAGE)}
                              </span>
                              <button
                                className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                onClick={handleNextPage}
                                disabled={endIndex >= totalSponsors || isLoading}
                              >
                                <ChevronRight className="h-5 w-5" />
                              </button>
                            </div>
                            <div className="w-[150px]"></div> {/* This empty div balances the layout */}
                          </div>

                          {deleteConfirmation.isOpen && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                              <div className="bg-white p-6 rounded-lg">
                                <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                                <p className="mb-4">Are you sure you want to delete this sponsor?</p>
                                <div className="flex justify-end">
                                  <button
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
                                    onClick={cancelDelete}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                                    onClick={deleteSponsor}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    };

                    export default Sponsors;