import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  Timestamp,
  updateDoc,
  orderBy,
  limit,
  startAfter,
  Query,
  DocumentData,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../firebase";
import { ChevronLeft, ChevronRight, ArrowUp, ArrowDown, Edit, Trash2 } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Ajutor {
  id: string;
  "Contract nr.": string;
  amount: number;
  beneficiar: string;
  categorie: "Deces" | "Medical";
  date: Timestamp;
  filename: string;
  fileurl: string;
  documentFilename: string;
  documentFileurl: string;
}

type NewAjutor = Omit<Ajutor, "id">;

const ITEMS_PER_PAGE = 50;

const Ajutoare: React.FC = () => {
  const [ajutoare, setAjutoare] = useState<Ajutor[]>([]);
  const [newAjutor, setNewAjutor] = useState<NewAjutor>({
    "Contract nr.": "",
    amount: 0,
    beneficiar: "",
    categorie: "Deces",
    date: Timestamp.now(),
    filename: "",
    fileurl: "",
    documentFilename: "",
    documentFileurl: "",
  });
  const [editingAjutor, setEditingAjutor] = useState<Ajutor | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [documentFile, setDocumentFile] = useState<File | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ isOpen: boolean; ajutorId: string | null }>({
    isOpen: false,
    ajutorId: null,
  });
  const [filePreview, setFilePreview] = useState<{ isOpen: boolean; url: string; type: string }>({
    isOpen: false,
    url: "",
    type: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<"beneficiar" | "date">("beneficiar");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [totalAjutoare, setTotalAjutoare] = useState(0);

  useEffect(() => {
    fetchAjutoare();
    fetchTotalAjutoare();
  }, [sortField, sortDirection, currentPage, searchTerm]);

  const fetchTotalAjutoare = async () => {
    try {
      const snapshot = await getDocs(collection(db, "ajutoare"));
      setTotalAjutoare(snapshot.size);
    } catch (err) {
      console.error("Error fetching total ajutoare:", err);
    }
  };

  const fetchAjutoare = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let ajutoareQuery: Query<DocumentData> = collection(db, "ajutoare");

      ajutoareQuery = query(ajutoareQuery, orderBy(sortField, sortDirection));

      if (currentPage > 1 && lastVisible) {
        ajutoareQuery = query(ajutoareQuery, startAfter(lastVisible));
      }

      ajutoareQuery = query(ajutoareQuery, limit(ITEMS_PER_PAGE));

      const ajutoareSnapshot = await getDocs(ajutoareQuery);

      if (!ajutoareSnapshot.empty) {
        setLastVisible(ajutoareSnapshot.docs[ajutoareSnapshot.docs.length - 1]);
      }

      let ajutoarList = ajutoareSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          "Contract nr.": data["Contract nr."],
          amount: Number(data.amount),
          beneficiar: data.beneficiar,
          categorie: data.categorie,
          date: data.date,
          filename: data.filename,
          fileurl: data.fileurl,
          documentFilename: data.documentFilename,
          documentFileurl: data.documentFileurl,
        } as Ajutor;
      });

      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        ajutoarList = ajutoarList.filter(ajutor => 
          ajutor.beneficiar.toLowerCase().includes(lowerSearchTerm) ||
          ajutor["Contract nr."].toLowerCase().includes(lowerSearchTerm)
        );
      }

      setAjutoare(ajutoarList);
    } catch (err) {
      console.error("Error fetching ajutoare:", err);
      setError("Failed to fetch ajutoare. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addAjutor = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let fileUrl = "";
      let fileName = "";
      let documentFileUrl = "";
      let documentFileName = "";

      if (file) {
        const storageRef = ref(storage, `ajutor_files/${file.name}`);
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
        fileName = file.name;
      }

      if (documentFile) {
        const documentStorageRef = ref(storage, `ajutor_documents/${documentFile.name}`);
        await uploadBytes(documentStorageRef, documentFile);
        documentFileUrl = await getDownloadURL(documentStorageRef);
        documentFileName = documentFile.name;
      }

      const ajutorWithDate = {
        ...newAjutor,
        amount: Number(newAjutor.amount),
        date: Timestamp.fromDate(newAjutor.date.toDate()),
        fileurl: fileUrl,
        filename: fileName,
        documentFileurl: documentFileUrl,
        documentFilename: documentFileName,
      };
      const docRef = await addDoc(collection(db, "ajutoare"), ajutorWithDate);
      const newAjutorWithId = { ...ajutorWithDate, id: docRef.id };
      setAjutoare([...ajutoare, newAjutorWithId]);
      setNewAjutor({
        "Contract nr.": "",
        amount: 0,
        beneficiar: "",
        categorie: "Deces",
        date: Timestamp.now(),
        filename: "",
        fileurl: "",
        documentFilename: "",
        documentFileurl: "",
      });
      setFile(null);
      setDocumentFile(null);
      setIsAddingNew(false);
      setTotalAjutoare(prev => prev + 1);
    } catch (err) {
      console.error("Error adding new ajutor:", err);
      setError("Failed to add new ajutor. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateAjutor = async () => {
    if (!editingAjutor) return;

    setIsLoading(true);
    setError(null);
    try {
      let fileUrl = editingAjutor.fileurl;
      let fileName = editingAjutor.filename;
      let documentFileUrl = editingAjutor.documentFileurl;
      let documentFileName = editingAjutor.documentFilename;

      if (file) {
        if (editingAjutor.fileurl) {
          const oldFileRef = ref(storage, editingAjutor.fileurl);
          await deleteObject(oldFileRef);
        }

        const storageRef = ref(storage, `ajutor_files/${file.name}`);
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
        fileName = file.name;
      }

      if (documentFile) {
        if (editingAjutor.documentFileurl) {
          const oldDocumentFileRef = ref(storage, editingAjutor.documentFileurl);
          await deleteObject(oldDocumentFileRef);
        }

        const documentStorageRef = ref(storage, `ajutor_documents/${documentFile.name}`);
        await uploadBytes(documentStorageRef, documentFile);
        documentFileUrl = await getDownloadURL(documentStorageRef);
        documentFileName = documentFile.name;
      }

      const updatedAjutor = {
        ...editingAjutor,
        amount: Number(editingAjutor.amount),
        date: Timestamp.fromDate(editingAjutor.date.toDate()),
        fileurl: fileUrl,
        filename: fileName,
        documentFileurl: documentFileUrl,
        documentFilename: documentFileName,
      };

      await updateDoc(doc(db, "ajutoare", editingAjutor.id), updatedAjutor);

      setAjutoare(ajutoare.map(a => a.id === editingAjutor.id ? updatedAjutor : a));
      setEditingAjutor(null);
      setFile(null);
      setDocumentFile(null);
    } catch (err) {
      console.error("Error updating ajutor:", err);
      setError("Failed to update ajutor. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = (id: string) => {
    setDeleteConfirmation({ isOpen: true, ajutorId: id });
  };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, ajutorId: null });
  };

  const deleteAjutor = async () => {
    if (!deleteConfirmation.ajutorId) return;

    setIsLoading(true);
    setError(null);
    try {
      const ajutorToDelete = ajutoare.find(a => a.id === deleteConfirmation.ajutorId);
      if (ajutorToDelete) {
        if (ajutorToDelete.fileurl) {
          const fileRef = ref(storage, ajutorToDelete.fileurl);
          await deleteObject(fileRef);
        }
        if (ajutorToDelete.documentFileurl) {
          const documentFileRef = ref(storage, ajutorToDelete.documentFileurl);
          await deleteObject(documentFileRef);
        }
      }
      await deleteDoc(doc(db, "ajutoare", deleteConfirmation.ajutorId));
      setAjutoare(ajutoare.filter((ajutor) => ajutor.id !== deleteConfirmation.ajutorId));
      setDeleteConfirmation({ isOpen: false, ajutorId: null });
      setTotalAjutoare(prev => prev - 1);
    } catch (err) {
      console.error("Error deleting ajutor:", err);
      setError("Failed to delete ajutor. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const openFilePreview = (url: string, fileName: string) => {
    const fileType = fileName.split('.').pop()?.toLowerCase();
    let type = "other";
    if (fileType === "pdf") {
      type = "pdf";
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileType || "")) {
      type = "image";
    }
    setFilePreview({ isOpen: true, url, type });
  };

  const closeFilePreview = () => {
    setFilePreview({ isOpen: false, url: "", type: "" });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handleSort = (field: "beneficiar" | "date") => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentPage(1);
    setLastVisible(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setLastVisible(null);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setNewAjutor({ ...newAjutor, date: Timestamp.fromDate(date) });
    }
  };

  const handleEditDateChange = (date: Date | null) => {
    if (date && editingAjutor) {
      setEditingAjutor({ ...editingAjutor, date: Timestamp.fromDate(date) });
    }
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + ajutoare.length - 1, totalAjutoare);

  return (
    <div className="p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Ajutoare</h1>
      <div className="mb-4">
        <button
          className="bg-[#93C572] hover:bg-[#7ba35e] text-white px-4 py-2 rounded transition duration-300 ease-in-out"
          onClick={() => setIsAddingNew(true)}
          disabled={isLoading}
        >
          Add New Ajutor
        </button>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4 flex justify-between items-center">
        <div className="flex-1 mr-4">
          <input
            type="text"
            placeholder="Search by beneficiary or contract number"
            value={searchTerm}
            onChange={handleSearch}
            className="p-2 border border-border rounded w-full bg-input text-text"
          />
        </div>
      </div>
      {isAddingNew && (
        <div className="mb-4 p-4 border border-border rounded bg-form">
          <input
            type="text"
            placeholder="Contract nr."
            value={newAjutor["Contract nr."]}
            onChange={(e) =>
                          setNewAjutor({ ...newAjutor, "Contract nr.": e.target.value })
                        }
                        className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
                      />
                      <input
                        type="number"
                        placeholder="Amount"
                        value={newAjutor.amount}
                        onChange={(e) =>
                          setNewAjutor({ ...newAjutor, amount: parseFloat(e.target.value) || 0 })
                        }
                        className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
                      />
                      <input
                        type="text"
                        placeholder="Beneficiary"
                        value={newAjutor.beneficiar}
                        onChange={(e) =>
                          setNewAjutor({ ...newAjutor, beneficiar: e.target.value })
                        }
                        className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
                      />
                      <select
                        value={newAjutor.categorie}
                        onChange={(e) =>
                          setNewAjutor({ ...newAjutor, categorie: e.target.value as "Deces" | "Medical" })
                        }
                        className="mb-2 p-2 border border-border rounded w-full bg-input text-text"
                      >
                        <option value="Deces">Deces</option>
                        <option value="Medical">Medical</option>
                      </select>
                      <div className="mb-2">
                        <DatePicker
                          selected={newAjutor.date.toDate()}
                          onChange={handleDateChange}
                          className="p-2 border border-border rounded w-full bg-input text-text"
                        />
                      </div>
                      <div className="mb-2">
                        <input
                          type="file"
                          onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                          className="p-2 border border-border rounded w-full bg-input text-text"
                        />
                        <p className="text-sm text-gray-500 mt-1">Contract</p>
                      </div>
                      <div className="mb-2">
                        <input
                          type="file"
                          onChange={(e) => setDocumentFile(e.target.files ? e.target.files[0] : null)}
                          className="p-2 border border-border rounded w-full bg-input text-text"
                        />
                        <p className="text-sm text-gray-500 mt-1">Document</p>
                      </div>
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
                        onClick={addAjutor}
                        disabled={isLoading}
                      >
                        {isLoading ? "Saving..." : "Save"}
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                        onClick={() => setIsAddingNew(false)}
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-table-header">
                          <th className="border border-border p-2 text-left">Nr.</th>
                          <th className="border border-border p-2 text-left">Contract nr.</th>
                          <th className="border border-border p-2 text-left">Amount</th>
                          <th 
                            className="border border-border p-2 text-left cursor-pointer"
                            onClick={() => handleSort("beneficiar")}
                          >
                            Beneficiary
                            {sortField === "beneficiar" && (
                              sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                            )}
                          </th>
                          <th className="border border-border p-2 text-left">Category</th>
                          <th 
                            className="border border-border p-2 text-left cursor-pointer"
                            onClick={() => handleSort("date")}
                          >
                            Date
                            {sortField === "date" && (
                              sortDirection === "asc" ? <ArrowUp className="inline ml-1 h-4 w-4" /> : <ArrowDown className="inline ml-1 h-4 w-4" />
                            )}
                          </th>
                          <th className="border border-border p-2 text-left">File</th>
                          <th className="border border-border p-2 text-left">Document</th>
                          <th className="border border-border p-2 text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ajutoare.map((ajutor, index) => (
                          <tr key={ajutor.id} className="bg-table-row hover:bg-table-row-hover">
                            <td className="border border-border p-2">{startIndex + index}</td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <input
                                  type="text"
                                  value={editingAjutor["Contract nr."]}
                                  onChange={(e) => setEditingAjutor({ ...editingAjutor, "Contract nr.": e.target.value })}
                                  className="w-full p-1 border rounded"
                                />
                              ) : (
                                ajutor["Contract nr."]
                              )}
                            </td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <input
                                  type="number"
                                  value={editingAjutor.amount}
                                  onChange={(e) => setEditingAjutor({ ...editingAjutor, amount: parseFloat(e.target.value) || 0 })}
                                  className="w-full p-1 border rounded"
                                />
                              ) : (
                                `RON ${Number(ajutor.amount).toFixed(2)}`
                              )}
                            </td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <input
                                  type="text"
                                  value={editingAjutor.beneficiar}
                                  onChange={(e) => setEditingAjutor({ ...editingAjutor, beneficiar: e.target.value })}
                                  className="w-full p-1 border rounded"
                                />
                              ) : (
                                ajutor.beneficiar
                              )}
                            </td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <select
                                  value={editingAjutor.categorie}
                                  onChange={(e) => setEditingAjutor({ ...editingAjutor, categorie: e.target.value as "Deces" | "Medical" })}
                                  className="w-full p-1 border rounded"
                                >
                                  <option value="Deces">Deces</option>
                                  <option value="Medical">Medical</option>
                                </select>
                              ) : (
                                ajutor.categorie
                              )}
                            </td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <DatePicker
                                  selected={editingAjutor.date.toDate()}
                                  onChange={handleEditDateChange}
                                  className="w-full p-1 border rounded"
                                />
                              ) : (
                                formatDate(ajutor.date.toDate())
                              )}
                            </td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <input
                                  type="file"
                                  onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                                  className="w-full p-1 border rounded"
                                />
                              ) : ajutor.fileurl ? (
                                <button
                                  onClick={() => openFilePreview(ajutor.fileurl, ajutor.filename)}
                                  className="text-blue-500 hover:underline"
                                >
                                  View File
                                </button>
                              ) : (
                                "No file"
                              )}
                            </td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <input
                                  type="file"
                                  onChange={(e) => setDocumentFile(e.target.files ? e.target.files[0] : null)}
                                  className="w-full p-1 border rounded"
                                />
                              ) : ajutor.documentFileurl ? (
                                <button
                                  onClick={() => openFilePreview(ajutor.documentFileurl, ajutor.documentFilename)}
                                  className="text-blue-500 hover:underline"
                                >
                                  View Document
                                </button>
                              ) : (
                                "No document"
                              )}
                            </td>
                            <td className="border border-border p-2">
                              {editingAjutor?.id === ajutor.id ? (
                                <>
                                  <button
                                    className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded mr-2 transition duration-300 ease-in-out"
                                    onClick={updateAjutor}
                                    disabled={isLoading}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-2 py-1 rounded transition duration-300 ease-in-out"
                                    onClick={() => setEditingAjutor(null)}
                                    disabled={isLoading}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <div className="flex space-x-2">
                                  <button
                                    className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                    onClick={() => setEditingAjutor(ajutor)}
                                    disabled={isLoading}
                                  >
                                    <Edit className="w-4 h-4 mr-1" /> Edit
                                  </button>
                                  <button
                                    className="bg-[#DC3545] hover:bg-[#c82333] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                                    onClick={() => confirmDelete(ajutor.id)}
                                    disabled={isLoading}
                                  >
                                    <Trash2 className="w-4 h-4 mr-1" /> Delete
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-8 flex justify-between items-center">
                    <span className="text-sm">
                      Showing {startIndex}-{endIndex} of {totalAjutoare}
                    </span>
                    <div className="flex-1 flex justify-center items-center space-x-2">
                      <button
                        className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1 || isLoading}
                      >
                        <ChevronLeft className="h-5 w-5" />
                      </button>
                      <span className="text-sm">
                        {currentPage} of {Math.ceil(totalAjutoare / ITEMS_PER_PAGE)}
                      </span>
                      <button
                        className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                        onClick={handleNextPage}
                        disabled={endIndex >= totalAjutoare || isLoading}
                      >
                        <ChevronRight className="h-5 w-5" />
                      </button>
                    </div>
                    <div className="w-[150px]"></div> {/* This empty div balances the layout */}
                  </div>

                  {deleteConfirmation.isOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                      <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                        <p className="mb-4">Are you sure you want to delete this ajutor?</p>
                        <div className="flex justify-end">
                          <button
                            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out"
                            onClick={cancelDelete}
                          >
                            Cancel
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                            onClick={deleteAjutor}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {filePreview.isOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                      <div className="bg-white p-6 rounded-lg w-3/4 h-3/4">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-xl font-bold">File Preview</h2>
                          <button
                            className="text-2xl"
                            onClick={closeFilePreview}
                          >
                            &times;
                          </button>
                        </div>
                        <div className="w-full h-5/6">
                          {filePreview.type === "pdf" && (
                            <iframe src={filePreview.url} className="w-full h-full" title="PDF preview"></iframe>
                          )}
                          {filePreview.type === "image" && (
                            <img src={filePreview.url} alt="File preview" className="max-w-full max-h-full mx-auto" />
                          )}
                          {filePreview.type === "other" && (
                            <div className="text-center">
                              <p>This file type cannot be previewed.</p>
                              <a href={filePreview.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                Download File
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            };

            export default Ajutoare;