import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, getDoc, updateDoc, orderBy, limit, startAfter, Query, DocumentData, Timestamp, DocumentSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { ChevronLeft, ChevronRight, Eye, FileText, RotateCcw } from 'lucide-react';

interface Proposal {
  id: string;
  anonim: boolean;
  userId: string;
  propunere: string;
  propunereFile: string;
  propunereURL: string;
  read: boolean;
  subject: string;
  createdAt: Timestamp;
  displayName?: string;
}

const ITEMS_PER_PAGE = 10;

const PropuneriPrimite: React.FC = () => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState<DocumentSnapshot | null>(null);
  const [totalProposals, setTotalProposals] = useState(0);
  const [viewingProposal, setViewingProposal] = useState<Proposal | null>(null);

  useEffect(() => {
    fetchTotalProposals();
  }, []);

  useEffect(() => {
    fetchProposals();
  }, [currentPage]);

  const fetchTotalProposals = async () => {
    try {
      const snapshot = await getDocs(collection(db, "propuneri"));
      setTotalProposals(snapshot.size);
    } catch (err) {
      console.error("Error fetching total proposals:", err);
      setError("Failed to fetch total proposals. Please try again.");
    }
  };

  const fetchProposals = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let proposalsQuery: Query<DocumentData> = collection(db, "propuneri");

      proposalsQuery = query(proposalsQuery, orderBy("createdAt", "desc"));

      if (currentPage > 1 && lastVisible) {
        proposalsQuery = query(proposalsQuery, startAfter(lastVisible));
      }

      proposalsQuery = query(proposalsQuery, limit(ITEMS_PER_PAGE));

      const proposalsSnapshot = await getDocs(proposalsQuery);

      if (!proposalsSnapshot.empty) {
        setLastVisible(proposalsSnapshot.docs[proposalsSnapshot.docs.length - 1]);
      } else {
        setLastVisible(null);
      }

      const proposalsList: Proposal[] = await Promise.all(
        proposalsSnapshot.docs.map(async (docSnapshot: DocumentSnapshot<DocumentData>) => {
          const data = docSnapshot.data() as Proposal;
          const id = docSnapshot.id;
          if (!data.anonim && data.userId) {
            const userDoc = await getDoc(doc(db, 'users', data.userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              data.displayName = userData.displayName || 'N/A';
            } else {
              data.displayName = 'N/A';
            }
          }
          return { ...data, id };
        })
      );

      setProposals(proposalsList);
    } catch (err) {
      console.error("Error fetching proposals:", err);
      setError("Failed to fetch proposals. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setLastVisible(null);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const truncateMessage = (message: string, wordCount: number) => {
    const words = message.split(' ');
    if (words.length > wordCount) {
      return words.slice(0, wordCount).join(' ') + '...';
    }
    return message;
  };

  const viewProposal = async (proposal: Proposal) => {
    setViewingProposal(proposal);
    if (!proposal.read) {
      await markAsRead(proposal);
    }
  };

  const markAsRead = async (proposal: Proposal) => {
    try {
      await updateDoc(doc(db, "propuneri", proposal.id), { read: true });
      setProposals(proposals.map(p => p.id === proposal.id ? { ...p, read: true } : p));
    } catch (err) {
      console.error("Error marking proposal as read:", err);
      setError("Failed to mark proposal as read. Please try again.");
    }
  };

  const markAsUnread = async (proposal: Proposal) => {
    try {
      await updateDoc(doc(db, "propuneri", proposal.id), { read: false });
      setProposals(proposals.map(p => p.id === proposal.id ? { ...p, read: false } : p));
      setViewingProposal(null);
    } catch (err) {
      console.error("Error marking proposal as unread:", err);
      setError("Failed to mark proposal as unread. Please try again.");
    }
  };

  const formatDate = (timestamp: Timestamp) => {
    return timestamp.toDate().toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const getName = (proposal: Proposal) => {
    if (proposal.anonim) return "Anonim";
    return proposal.displayName || "N/A";
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + proposals.length - 1, totalProposals);

  return (
    <div className="p-6 bg-background text-text">
      <h1 className="text-3xl font-bold mb-6">Propuneri Primite</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-table-header">
                <th className="border border-border p-2 text-left">Nr.</th>
                <th className="border border-border p-2 text-left">Date</th>
                <th className="border border-border p-2 text-left">Name</th>
                <th className="border border-border p-2 text-left">Subject</th>
                <th className="border border-border p-2 text-left">Message</th>
                <th className="border border-border p-2 text-left">File</th>
                <th className="border border-border p-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {proposals.map((proposal, index) => (
                <tr key={proposal.id} className={`bg-table-row hover:bg-table-row-hover ${proposal.read ? 'opacity-50' : ''}`}>
                  <td className="border border-border p-2">{startIndex + index}</td>
                  <td className="border border-border p-2">{formatDate(proposal.createdAt)}</td>
                  <td className="border border-border p-2">{getName(proposal)}</td>
                  <td className="border border-border p-2">{proposal.subject}</td>
                  <td className="border border-border p-2">{truncateMessage(proposal.propunere, 20)}</td>
                  <td className="border border-border p-2">
                    {proposal.propunereFile && (
                      <a href={proposal.propunereURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                        <FileText className="w-4 h-4 inline mr-1" />
                        {proposal.propunereFile}
                      </a>
                    )}
                  </td>
                  <td className="border border-border p-2">
                    <div className="flex space-x-2">
                      <button
                        className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                        onClick={() => viewProposal(proposal)}
                      >
                        <Eye className="w-4 h-4 mr-1" /> View
                      </button>
                      {proposal.read && (
                        <button
                          className="bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
                          onClick={() => markAsUnread(proposal)}
                        >
                          <RotateCcw className="w-4 h-4 mr-1" /> Mark Unread
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="mt-4 flex justify-between items-center">
        <span className="text-sm">
          Showing {startIndex}-{endIndex} of {totalProposals}
        </span>
        <div className="flex items-center space-x-2">
          <button
            className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
            onClick={handlePrevPage}
            disabled={currentPage === 1 || isLoading}
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <span className="text-sm">
            {currentPage} of {Math.ceil(totalProposals / ITEMS_PER_PAGE)}
          </span>
          <button
            className="bg-[#498EB0] hover:bg-[#3a7a9d] text-white px-3 py-1 rounded transition duration-300 ease-in-out flex items-center"
            onClick={handleNextPage}
            disabled={endIndex >= totalProposals || isLoading}
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
      </div>

      {viewingProposal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Proposal Details</h2>
              <button
                className="text-2xl"
                onClick={() => setViewingProposal(null)}
              >
                &times;
              </button>
            </div>
            <div>
              <p><strong>Date:</strong> {formatDate(viewingProposal.createdAt)}</p>
              <p><strong>Name:</strong> {getName(viewingProposal)}</p>
              <p><strong>Subject:</strong> {viewingProposal.subject}</p>
              <p><strong>Message:</strong></p>
              <div className="mt-2 p-4 bg-gray-100 rounded">{viewingProposal.propunere}</div>
              {viewingProposal.propunereFile && (
                <p className="mt-2">
                  <strong>Attached File:</strong> 
                  <a href={viewingProposal.propunereURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline ml-2">
                    <FileText className="w-4 h-4 inline mr-1" />
                    {viewingProposal.propunereFile}
                  </a>
                </p>
              )}
            </div>
            <div className="mt-4">
              <button
                className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out"
                onClick={() => markAsUnread(viewingProposal)}
              >
                Mark as Unread
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropuneriPrimite;