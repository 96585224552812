import React, { useEffect, useState } from 'react';

const CarouselSection: React.FC<{ title: string; description: string; imagePaths: string[] }> = ({ title, description, imagePaths }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % imagePaths.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [imagePaths.length]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % imagePaths.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + imagePaths.length) % imagePaths.length);
  };

  return (
    <div className="carousel-section border-2 border-gray-300 rounded-lg p-6 mb-8">
      <h1 className="text-3xl font-bold mb-4 text-center">{title}</h1>
      <p className="text-lg mb-6 text-center">{description}</p>

      <div className="relative w-full max-w-4xl mx-auto">
        <div className="overflow-hidden rounded-lg">
          {imagePaths.map((path, index) => (
            <div
              key={index}
              className={`transition-opacity duration-500 ${
                index === currentSlide ? 'opacity-100' : 'opacity-0 hidden'
              }`}
            >
              <img
                src={path}
                alt={`Slide ${index + 1}`}
                className="w-full h-auto"
              />
            </div>
          ))}
        </div>

        {/* Slider controls */}
        <button
          type="button"
          className="absolute top-1/2 left-4 z-30 flex items-center justify-center h-10 w-10 rounded-full bg-black/30 hover:bg-black/50 focus:ring-4 focus:ring-white focus:outline-none"
          onClick={prevSlide}
        >
          <span className="sr-only">Previous</span>
          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
          </svg>
        </button>
        <button
          type="button"
          className="absolute top-1/2 right-4 z-30 flex items-center justify-center h-10 w-10 rounded-full bg-black/30 hover:bg-black/50 focus:ring-4 focus:ring-white focus:outline-none"
          onClick={nextSlide}
        >
          <span className="sr-only">Next</span>
          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </button>
      </div>

      {/* Slider indicators */}
      <div className="flex justify-center mt-4 flex-wrap">
        {imagePaths.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-2 h-2 rounded-full mx-1 mb-1 ${
              index === currentSlide ? 'bg-gray-800' : 'bg-gray-300'
            }`}
            aria-current={index === currentSlide}
            aria-label={`Slide ${index + 1}`}
            onClick={() => setCurrentSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

const Campanii: React.FC = () => {
  const impadurireImages = [
    "/Impadurire/VYO_0001.jpg", "/Impadurire/VYO_0002.jpg", "/Impadurire/VYO_0003.jpg", "/Impadurire/VYO_0004.jpg",
    "/Impadurire/VYO_0005.jpg", "/Impadurire/VYO_0006.jpg", "/Impadurire/VYO_0007.jpg", "/Impadurire/VYO_0008.jpg",
    "/Impadurire/VYO_0009.jpg", "/Impadurire/VYO_0010.jpg", "/Impadurire/VYO_0011.jpg", "/Impadurire/VYO_0012.jpg",
    "/Impadurire/VYO_0013.jpg", "/Impadurire/VYO_0014.jpg", "/Impadurire/VYO_0015.jpg", "/Impadurire/VYO_0016.jpg",
    "/Impadurire/VYO_0017.jpg", "/Impadurire/VYO_0018.jpg", "/Impadurire/VYO_0019.jpg", "/Impadurire/VYO_0021.jpg",
    "/Impadurire/VYO_0022.jpg", "/Impadurire/VYO_0023.jpg", "/Impadurire/VYO_0024.jpg", "/Impadurire/VYO_0025.jpg",
    "/Impadurire/VYO_0026.jpg", "/Impadurire/VYO_0027.jpg", "/Impadurire/VYO_0028.jpg", "/Impadurire/VYO_0029.jpg",
    "/Impadurire/VYO_0030.jpg", "/Impadurire/VYO_0031.jpg", "/Impadurire/VYO_0032.jpg", "/Impadurire/VYO_0033.jpg",
    "/Impadurire/VYO_0034.jpg", "/Impadurire/VYO_0035.jpg", "/Impadurire/VYO_0036.jpg", "/Impadurire/VYO_0037.jpg",
    "/Impadurire/VYO_0038.jpg", "/Impadurire/VYO_0039.jpg", "/Impadurire/VYO_0040.jpg", "/Impadurire/VYO_0041.jpg",
    "/Impadurire/VYO_0042.jpg", "/Impadurire/VYO_0043.jpg", "/Impadurire/VYO_0044.jpg", "/Impadurire/VYO_0045.jpg",
    "/Impadurire/VYO_0046.jpg", "/Impadurire/VYO_0047.jpg", "/Impadurire/VYO_0048.jpg", "/Impadurire/VYO_0049.jpg",
    "/Impadurire/VYO_0050.jpg", "/Impadurire/VYO_0051.jpg", "/Impadurire/VYO_0052.jpg", "/Impadurire/VYO_0053.jpg",
    "/Impadurire/VYO_0054.jpg", "/Impadurire/VYO_0055.jpg", "/Impadurire/VYO_0056.jpg", "/Impadurire/VYO_0057.jpg",
    "/Impadurire/VYO_0058.jpg", "/Impadurire/VYO_0059.jpg", "/Impadurire/VYO_0060.jpg", "/Impadurire/VYO_0061.jpg",
    "/Impadurire/VYO_0062.jpg", "/Impadurire/VYO_0063.jpg", "/Impadurire/VYO_0064.jpg", "/Impadurire/VYO_0065.jpg",
    "/Impadurire/VYO_0066.jpg", "/Impadurire/VYO_0067.jpg", "/Impadurire/VYO_0068.jpg", "/Impadurire/VYO_0069.jpg",
    "/Impadurire/VYO_0070.jpg", "/Impadurire/VYO_0071.jpg", "/Impadurire/VYO_0072.jpg", "/Impadurire/VYO_0073.jpg",
    "/Impadurire/VYO_0074.jpg", "/Impadurire/VYO_0075.jpg", "/Impadurire/VYO_0076.jpg", "/Impadurire/VYO_0077.jpg",
    "/Impadurire/VYO_0078.jpg", "/Impadurire/VYO_0079.jpg", "/Impadurire/VYO_0080.jpg", "/Impadurire/VYO_0081.jpg",
    "/Impadurire/VYO_0082.jpg", "/Impadurire/VYO_0083.jpg", "/Impadurire/VYO_0084.jpg", "/Impadurire/VYO_0085.jpg",
    "/Impadurire/VYO_0086.jpg", "/Impadurire/VYO_0087.jpg", "/Impadurire/VYO_0088.jpg", "/Impadurire/VYO_0089.jpg",
    "/Impadurire/VYO_0090.jpg", "/Impadurire/VYO_0091.jpg", "/Impadurire/VYO_0092.jpg", "/Impadurire/VYO_0093.jpg",
    "/Impadurire/VYO_0094.jpg", "/Impadurire/VYO_0095.jpg", "/Impadurire/VYO_0096.jpg", "/Impadurire/VYO_0097.jpg",
    "/Impadurire/VYO_0098.jpg", "/Impadurire/VYO_0099.jpg", "/Impadurire/VYO_0100.jpg", "/Impadurire/VYO_0101.jpg"
  ];

  const ecologizareImages = [
    "/Ecologizare/0f386abe-f4b4-493b-8c0d-19262d07a8f1.jpg",
    "/Ecologizare/4e851588-f154-4710-8038-45f3f574c633 - Edited.png",
    "/Ecologizare/9b7e1bde-ed02-467c-be48-4720e6e7e58d.jpg",
    "/Ecologizare/27ee2572-313f-47d1-8cd6-e2dc2677417e.jpg",
    "/Ecologizare/40f74474-c2db-4838-9bda-3c7c22d9eefe.jpg",
    "/Ecologizare/58fdda48-894f-435c-a236-ad4cad11da99.jpg",
    "/Ecologizare/095d63be-0da1-4084-8902-4f461f966491.jpg",
    "/Ecologizare/642fc00b-9b9d-4dea-bf53-1a2c1df1c230.jpg",
    "/Ecologizare/15834d39-82e7-4a31-94f7-6ce5ad887ab9.jpg",
    "/Ecologizare/080841d6-631f-4488-a87d-47fa212ec3b5.jpg",
    "/Ecologizare/b2c0a163-c3e6-4ea0-b1a2-6184e3644076.jpg",
    "/Ecologizare/b2f4a3c2-462c-497f-a1f0-0583932323f1.jpg",
    "/Ecologizare/b438c414-c144-4560-8ca2-9df1fa927344.jpg",
    "/Ecologizare/d17ec9ad-f0fb-4f81-b6b0-bec8271f0e2c.jpg",
    "/Ecologizare/dab79877-b277-4258-9f50-682186335318.jpg",
    "/Ecologizare/df1fc6ec-8d41-4c26-8e8d-b711267a753f - Edited.png",
    "/Ecologizare/df778e75-93fb-4d7b-84ff-240280088d01.jpg",
    "/Ecologizare/eafbb9bc-4e1c-49d2-8646-9fbded96ecef.jpg",
    "/Ecologizare/f8e83700-c4f8-44d9-9358-f319049ff484.jpg",
    "/Ecologizare/f263ee8b-9e86-49eb-a63e-cf472c925bed.jpg",
    "/Ecologizare/IMG_20220722_102928.jpg",
    "/Ecologizare/IMG_20220722_103146.jpg",
    "/Ecologizare/IMG_20220722_103156.jpg",
    "/Ecologizare/IMG_20220722_103643.jpg"
  ];

  const targCraciunImages = [
    "/Targ de Craciun/316677895_146149328177065_2097381404379533271_n.jpg",
    "/Targ de Craciun/318203243_146150061510325_770622773284894001_n.jpg",
    "/Targ de Craciun/320084359_6074810545891329_2947846166696462798_n.jpg",
    "/Targ de Craciun/IMG-20221207-WA0013.jpg",
    "/Targ de Craciun/IMG-20221214-WA0005.jpg"
  ];

  const spectacolCaritabilImages = [
    "/Spectacol Caritabil/1d7ee03d-97cd-4321-b7e3-5418e54384ac.jpg",
    "/Spectacol Caritabil/9ae50a17-edcd-4598-8589-b3bf82c6c242.jpg",
    "/Spectacol Caritabil/54e4675e-8dc1-4334-9168-81845f2cb407.jpg",
    "/Spectacol Caritabil/0069bebc-0c85-47f7-b444-32f2ea3c70bf.jpg",
    "/Spectacol Caritabil/9844bb2b-16b1-4f21-869e-99271341a431.jpg"
  ];

  return (
    <div className="campanii-container p-4">
      <CarouselSection
        title="Acțiune de Împădurire, Livada, Aprilie 2023"
        description="Împreună pentru un viitor mai verde! În aprilie 2023, am organizat o acțiune de împădurire în Livada, plantând sute de puieți pentru a combate defrișările și a contribui la sănătatea mediului înconjurător. Voluntarii noștri dedicați au lucrat cu entuziasm, demonstrând că fiecare copac plantat este un pas către un ecosistem mai sănătos și un aer mai curat pentru generațiile viitoare."
        imagePaths={impadurireImages}
      />

      <CarouselSection
        title="Campanie Ecologizare, 22 iulie 2022"
        description="În iulie 2022, am organizat o campanie de ecologizare pentru a curăța și proteja mediul înconjurător. Voluntarii noștri dedicați au colectat deșeuri, au plantat flori și au contribuit la înfrumusețarea spațiilor verzi din comunitatea noastră. Împreună, am făcut un pas important către un mediu mai curat și mai sănătos pentru toți."
        imagePaths={ecologizareImages}
      />

      <CarouselSection
        title="Târg de Crăciun, Decembrie 2022"
        description="Sărbătorește magia Crăciunului la târgul nostru festiv! Bucură-te de produse artizanale, delicatese tradiționale și atmosfera încântătoare a sărbătorilor de iarnă. Vino să descoperi spiritul Crăciunului împreună cu noi!"
        imagePaths={targCraciunImages}
      />

      <CarouselSection
        title="Spectacol Caritabil, Decembrie 2022"
        description="Alătură-te nouă pentru o seară magică de divertisment și generozitate! Spectacolul nostru caritabil aduce împreună artiști talentați și inimi generoase pentru a sprijini o cauză nobilă. Fiecare bilet cumpărat și fiecare donație făcută vor contribui la schimbarea în bine a vieților celor în nevoie. Vino să fii parte din această experiență unică, plină de muzică, dans și speranță!"
        imagePaths={spectacolCaritabilImages}
      />
    </div>
    );
    };

    export default Campanii;