import React, { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { PDFDocument, StandardFonts } from 'pdf-lib';

interface FormData {
  Ap: string;
  Bloc: string;
  CNP: string;
  "Cod postal": string;
  Email: string;
  Etaj: string;
  Fax: string;
  "Initiala tatalui": string;
  Judet: string;
  Localitate: string;
  Numar: string;
  Nume: string;
  Prenume: string;
  Scara: string;
  Strada: string;
  Telefon: string;
}

const Formular: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const fillPDFForm = async (data: FormData) => {
    const formUrl = '/F23022.pdf';
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();

    // Embed the font
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Mapping between form fields and PDF fields
    const fieldMapping: Record<keyof FormData, string> = {
      Nume: 'Nume',
      Prenume: 'Prenume',
      "Initiala tatalui": 'Initiala tatalui',
      CNP: 'Cod numeric personal / Număr de identificare fiscală',
      Strada: 'Strada',
      Numar: 'Numar',
      Bloc: 'Bloc',
      Scara: 'Scara',
      Etaj: 'Etaj',
      Ap: 'Ap',
      Judet: 'Judet/Sector',
      Localitate: 'Localitate',
      "Cod postal": 'Cod postal',
      Telefon: 'Telefon',
      Email: 'E-mail',
      Fax: 'Fax'
    };

    Object.entries(fieldMapping).forEach(([key, pdfField]) => {
      try {
        const field = form.getTextField(pdfField);
        if (field) {
          field.setText(data[key as keyof FormData]);
          field.updateAppearances(font);
        } else {
          console.warn(`Field "${pdfField}" not found in the PDF form`);
        }
      } catch (error) {
        console.warn(`Error setting field "${pdfField}":`, error);
      }
    });

    return await pdfDoc.save();
  };

  const downloadPDF = (pdfBytes: Uint8Array) => {
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'F23022_filled.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleGeneratePDF = async () => {
    const user = auth.currentUser;
    if (!user) {
      setError('You must be logged in to generate the PDF.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Fetch form data from Firestore
      const docRef = doc(db, 'formular', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const formData = docSnap.data() as FormData;

        // Fill PDF form
        console.log('Filling PDF form for user:', user.uid);
        const filledPdfBytes = await fillPDFForm(formData);
        console.log('PDF form filled for user:', user.uid);

        // Trigger PDF download
        downloadPDF(filledPdfBytes);

        setSuccess('PDF generated and download started successfully!');
      } else {
        setError('No form data found. Please fill out your form data first.');
      }
    } catch (err: any) {
      console.error('Error generating PDF:', err);
      setError(`Error generating PDF: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-8 p-4">
      <h1 className="text-3xl font-bold mb-6 text-center">Formular 230 - Generate PDF</h1>
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">{error}</div>}
      {success && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">{success}</div>}
      <div className="text-center">
        <button
          onClick={handleGeneratePDF}
          className="px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200"
          disabled={loading}
        >
          {loading ? 'Generating PDF...' : 'Generate and Download PDF'}
        </button>
      </div>
      <p className="mt-4 text-center text-sm text-gray-600">
        Click the button above to generate a PDF filled with your form data. Make sure you have filled out your form data in your profile first.
      </p>
    </div>
  );
};

export default Formular;