import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db, auth } from "../firebase";
import Profile from "./profile";
import Campanii from "./campanii";
import { Propuneri } from "./propuneri";
import Formular from "./formular";
import SondajeUsers from "./sondajeusers";
import Chatbot from "./Chatbot";

interface Post {
  id: string;
  text: string;
  imageUrl: string;
  userId: string;
}

interface SidebarItem {
  path: string;
  label: string;
  icon: string;
}

const sidebarItems: SidebarItem[] = [
  { path: "/", label: "Home", icon: "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" },
  { path: "/campanii", label: "Campanii", icon: "M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" },
  { path: "/propuneri", label: "Propuneri", icon: "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" },
  { path: "/formular", label: "Formular", icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" },
  { path: "/sondaje", label: "Sondaje", icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" },
  { path: "/profile", label: "Profil", icon: "M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" },
];

const Home: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const postsQuery = query(
      collection(db, "posts"),
      orderBy("createdAt", "desc"),
    );
    const unsubscribe = onSnapshot(postsQuery, (snapshot) => {
      const newPosts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Post[];
      setPosts(newPosts);
    });
    return () => unsubscribe();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebarOnMobile = () => {
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    closeSidebarOnMobile();
  };

  const PostFeed: React.FC = () => (
    <div className={`home p-4 ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <h1 className="text-2xl font-bold mb-4 text-center">Home Feed</h1>
      <div className="max-w-2xl mx-auto">
        {posts.map((post) => (
          <div
            key={post.id}
            className={`post shadow-md rounded-lg p-4 mb-4 ${
              isDarkTheme ? 'bg-gray-800' : 'bg-white'
            }`}
          >
            {post.imageUrl && (
              <img
                src={post.imageUrl}
                alt="Post"
                className="w-full h-64 object-cover rounded-lg mb-2"
              />
            )}
            <p className={isDarkTheme ? 'text-gray-200' : 'text-gray-800'}>{post.text}</p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={`flex h-screen ${isDarkTheme ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className={`${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 transform transition-transform duration-300 ease-in-out fixed md:static top-0 left-0 h-full w-64 ${
        isDarkTheme ? 'bg-gray-800' : 'bg-green-600'
      } shadow-md z-40 flex flex-col`}>
        <div className="flex-shrink-0 p-4">
          <img
            src="/FreeWayWhite.png"
            alt="Logo"
            className="w-3/4 mx-auto"
          />
        </div>
        <div className="w-full h-px bg-white opacity-50 my-2"></div>
        <nav className="flex-grow mt-2">
          {sidebarItems.map((item) => (
            <button
              key={item.path}
              onClick={() => handleNavigation(item.path)}
              className={`flex items-center py-3 px-4 my-1 font-inter font-medium text-base md:text-sm w-full text-left ${
                isDarkTheme
                  ? "text-white hover:bg-gray-700"
                  : "text-white hover:bg-green-700"
              } ${
                location.pathname === item.path ? (isDarkTheme ? 'bg-gray-700' : 'bg-green-700') : ""
              }`}
            >
              <div className="w-7 h-7 mr-3 bg-white bg-opacity-20 rounded-md flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d={item.icon} />
                </svg>
              </div>
              {item.label}
            </button>
          ))}
        </nav>
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            <span className="font-inter font-medium text-sm text-white">Dark Theme</span>
            <div
              className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
                isDarkTheme ? 'bg-green-500' : 'bg-gray-300'
              }`}
              onClick={toggleTheme}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                  isDarkTheme ? 'translate-x-6' : ''
                }`}
              ></div>
            </div>
          </div>
          <button
            onClick={handleSignOut}
            className="w-full py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700 font-inter font-medium text-sm flex items-center justify-center"
          >
            Logout
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto relative">
        <button
          className={`md:hidden fixed top-4 right-4 z-50 p-2 rounded-md ${
            isDarkTheme ? 'bg-gray-800 text-white' : 'bg-gray-100 text-current'
          }`}
          onClick={toggleSidebar}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <Routes>
          <Route index element={<PostFeed />} />
          <Route path="profile" element={<Profile />} />
          <Route path="campanii" element={<Campanii />} />
          <Route path="propuneri" element={<Propuneri />} />
          <Route path="formular" element={<Formular />} />
          <Route path="sondaje/*" element={<SondajeUsers />} />
        </Routes>

        {/* Chatbot toggle button */}
        <button
          className={`fixed bottom-4 right-4 z-50 p-3 rounded-full ${
            isDarkTheme ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'
          } shadow-lg hover:bg-blue-700 transition-colors duration-300`}
          onClick={toggleChatbot}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
          </svg>
        </button>

        {/* Chatbot component */}
        {isChatbotOpen && (
          <div className={`fixed bottom-20 right-4 w-80 h-96 rounded-lg shadow-xl overflow-hidden ${
            isDarkTheme ? 'bg-gray-800' : 'bg-white'
          }`}>
            <Chatbot />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;