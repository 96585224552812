import React, { useState } from "react";
import { Link } from "react-router-dom";

interface PasswordResetResponse {
  success: boolean;
  message: string;
}

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");
    setError("");

    try {
      const response = await fetch('https://us-central1-web-app-booking.cloudfunctions.net/sendPasswordResetEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result: PasswordResetResponse = await response.json();

      if (result.success) {
        setMessage(result.message || "Un email de resetare a parolei a fost trimis. Verificați căsuța de email.");
      } else {
        setError(result.message || "Nu s-a putut trimite email-ul de resetare a parolei. Vă rugăm să încercați din nou.");
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      if (error instanceof Error) {
        if (error.message.includes('Failed to fetch') || error.message.includes('Network Error')) {
          setError("Eroare de conexiune la server. Vă rugăm să verificați conexiunea la internet și să încercați din nou.");
        } else {
          setError(`Eroare: ${error.message}`);
        }
      } else {
        setError("A apărut o eroare neașteptată. Vă rugăm să încercați din nou mai târziu.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-48 w-auto"
            src="/LogoFreeWay.png"
            alt="Asociatia Free Way"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Resetați parola
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Adresă de email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Adresă de email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          {message && <p className="text-green-500 text-sm mt-2">{message}</p>}
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              disabled={isLoading}
            >
              {isLoading ? "Se trimite..." : "Trimite email de resetare"}
            </button>
          </div>
        </form>
        <div className="text-center">
          <Link
            to="/"
            className="font-medium text-green-600 hover:text-green-500"
          >
            Înapoi la Autentificare
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;