import React, { useState, useRef, useEffect } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../firebase';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_CHAR_COUNT = 1000;

export const Propuneri: React.FC = () => {
  const [subject, setSubject] = useState('');
  const [propunere, setPropunere] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [anonim, setAnonim] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => setAlert(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.size > MAX_FILE_SIZE) {
        setAlert({ type: 'error', message: 'Fișierul este prea mare. Dimensiunea maximă este de 5MB.' });
        if (fileInputRef.current) fileInputRef.current.value = '';
      } else {
        setFile(selectedFile);
      }
    }
  };

  const handlePropunereChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const content = e.target.value;
    if (content.length <= MAX_CHAR_COUNT) {
      setPropunere(content);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (subject.trim() === '' || propunere.trim() === '') {
      setAlert({ type: 'error', message: 'Vă rugăm să completați atât subiectul cât și propunerea.' });
      return;
    }
    setIsSubmitting(true);

    try {
      let propunereURL = '';
      let propunereFile = '';
      if (file) {
        const storageRef = ref(storage, `propuneri/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        propunereURL = await getDownloadURL(storageRef);
        propunereFile = file.name;
      }

      const user = auth.currentUser;
      const displayName = user ? user.displayName : 'Anonymous';

      const propunereDoc = {
        subject,
        propunere,
        propunereFile,
        propunereURL,
        anonim,
        read: false,
        createdAt: serverTimestamp(),
        displayName: anonim ? 'Anonymous' : displayName
      };

      await addDoc(collection(db, 'propuneri'), propunereDoc);

      setAlert({ type: 'success', message: 'Propunerea a fost trimisă cu succes!' });
      setSubject('');
      setPropunere('');
      setFile(null);
      setAnonim(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error("Error submitting proposal:", error);
      setAlert({ type: 'error', message: 'A apărut o eroare la trimiterea propunerii. Vă rugăm încercați din nou.' });
    }

    setIsSubmitting(false);
  };

  return (
    <div className="propuneri-container p-4 max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Propuneri</h1>

      {alert && (
        <div className={`p-4 mb-4 rounded-md ${alert.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {alert.message}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            Subiect
          </label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="propunere" className="block text-sm font-medium text-gray-700 mb-1">
            Propunerea ta
          </label>
          <textarea
            id="propunere"
            value={propunere}
            onChange={handlePropunereChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 h-64 resize-none"
            required
          />
          <p className="text-sm text-gray-500 mt-1">
            {propunere.length}/{MAX_CHAR_COUNT} caractere
          </p>
        </div>

        <div>
          <label htmlFor="file" className="block text-sm font-medium text-gray-700 mb-1">
            Încarcă un fișier (opțional, max 5MB)
          </label>
          <input
            type="file"
            id="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
          />
          {file && (
            <p className="text-sm text-gray-500 mt-1">
              Fișier selectat: {file.name}
            </p>
          )}
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="anonim"
            checked={anonim}
            onChange={(e) => setAnonim(e.target.checked)}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="anonim" className="ml-2 block text-sm text-gray-900">
            Trimite mesaj anonim
          </label>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
            isSubmitting
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-[#19A34A] hover:bg-[#15803D] text-white'
          }`}
        >
          {isSubmitting ? 'Se trimite...' : 'Trimite'}
        </button>
      </form>
    </div>
  );
};